import React from 'react';
import PropTypes from 'prop-types';
import { ShowButton } from 'react-admin';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ThemeProvider, makeStyles } from '@mui/styles';

import { themeShow } from '../views/Show';

const useStyles = makeStyles((theme) => ({
	docked: {
		position: 'absolute'
	},
	drawer: {
		width: '400px',
		paddingTop: '48px',
		backgroundColor: theme.palette.background.paper,
		'& *': {
			boxShadow: 'none'
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	divider: {
		marginBottom: '24px'
	}
}));


const CustomDrawer = ({ children, actions, record, basePath, forwardedRef, ...props}) => {
	const classes =  useStyles();

	return (
		<ThemeProvider theme={themeShow}>
			<Drawer classes={{ docked: classes.docked, paper: classes.drawer }} {...props} ref={forwardedRef}>
				<div className={classes.header}>
					<IconButton onClick={props.onClose}>
						{props.anchor === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
					<div>
						{actions && React.cloneElement(actions, { basePath, record })} 
						{(record && basePath) && <ShowButton record={record} basePath={basePath} />}
					</div>
				</div>
				<Divider className={classes.divider} />
				{React.cloneElement(children, { basePath, record })} 
			</Drawer>
		</ThemeProvider>
	);
};

const Element = React.forwardRef(({ variant = 'persistent', anchor = 'right', ...props }, ref) => <CustomDrawer variant={variant} anchor={anchor} {...props} forwardedRef={ref} />);

Element.propTypes = {
	variant: PropTypes.oneOf(["permanent", "persistent", "temporary"]),
	anchor: PropTypes.oneOf(["left", "right"])
};

Element.displayName = "Drawer";

export default Element;
