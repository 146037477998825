import { RichTextField as RARichTextField } from 'react-admin';

const sx = {
    '& ul li p': {
        margin: 0
    }
};

const RichTextField = (props) => (
    <RARichTextField sx={sx} {...props} />
);

export default RichTextField;
