import React, { useState } from 'react';
import { Datagrid } from 'react-admin';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Drawer from './layout/Drawer';

const hasParentClass = (element, classnames) => {
	if (typeof element.className == 'string' && element.className && element.className.split(' ').some(elementClass => classnames.some(className => elementClass.includes(className)))) return true;
	return element.parentNode && hasParentClass(element.parentNode, classnames);
};

const CustomDatagrid = ({ children, exclude = [], drawer, drawerActions, ...props }) => {
	const [showDrawer, setShowDrawer] = useState(false);
	const [record, setRecord] = useState(null);
	const [prevRecord, setPrevRecord] = useState(null);

	const rowClick = (id, basePath, newRecord) => {
		setShowDrawer(true);
		setPrevRecord(record);
		setRecord(newRecord);
	};

	const handleClose = () => {
		setShowDrawer(false);
		setRecord(null);
		setPrevRecord(null);
	};

	const handleClickAway = e => {
		// Check the nodeName is a weird fix for component with simple iterator and referenceinput
		if (e.target.nodeName === 'BODY' || hasParentClass(e.target, ['MuiDialog', 'RaAutocompleteInput'])) {
			e.stopPropagation();
			return;
		}

		if (prevRecord === null || record === null || prevRecord === record) {
			handleClose();
		}
		else {
			setPrevRecord(record);
		}
	};

	return (
		<>
			<Datagrid {...props} rowClick={rowClick}>
				{children.map(child => {
					if (!child)
						return null;

					if (!child.props.label && !child.props.source)
						return child;
					return exclude.includes(child.props?.label?.toLowerCase()) || exclude.includes(child.props?.source?.toLowerCase()) ? null : child;
				})}
			</Datagrid>
			{(drawer && record) && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Drawer open={showDrawer} onClose={handleClose} record={record} basePath={props.basePath} actions={drawerActions}>
						{drawer}
					</Drawer>
				</ClickAwayListener>
			)}
		</>
	);
};

export default CustomDatagrid;