import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import ProjectList from './ProjectList';
import ProjectEdit from './ProjectEdit';
import ProjectCreate from './ProjectCreate';
import ProjectShow from './ProjectShow';


const projects = {
	list: ProjectList,
	edit: ProjectEdit,
	create: ProjectCreate,
	show: ProjectShow,
	icon: ContentPasteIcon
};

export default projects;
