import React from 'react';
import { List, TopToolbar, SearchInput, SortButton, useStore } from 'react-admin';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { DefaultListActions } from '../../common/views/List';
import MonthlyRow from './MonthlyRow';
import MonthlyGrid from './MonthlyGrid';

const buttonGroupSx = { marginBottom: '-2px' };
const inactiveButtonSx = { color: (theme) => theme.palette.action.disabled };


const ViewButton = ({ viewGrid, setViewGrid }) => {
    const handleClickList = () => {
        setViewGrid(false);
    };

    const handleClickGrid = () => {
        setViewGrid(true);
    };

    return (
        <ButtonGroup size="small" variant="text" sx={buttonGroupSx}>
            <Button onClick={handleClickGrid} sx={viewGrid ? {} : inactiveButtonSx}><ViewModuleIcon /></Button>
            <Button onClick={handleClickList} sx={viewGrid ? inactiveButtonSx : {}}><ViewHeadlineIcon /></Button>
        </ButtonGroup>
    );
};

const MonthlyListActions = (props) => (
    <TopToolbar>
        <DefaultListActions>
            {props.viewGrid && <SortButton fields={['created_at']} />}
            <ViewButton {...props} />
        </DefaultListActions>
    </TopToolbar>
);

const monthlyFilters = [
	<SearchInput source="q" alwaysOn />
];

const MonthlyList = props => {
    const [viewGrid, setViewGrid] = useStore('monthlies.list.viewGrid', true);

    let customProps = {};
    if (viewGrid) {
        customProps = { component: 'div' };
    }

    return (
        <List {...props} perPage={25} actions={<MonthlyListActions viewGrid={viewGrid} setViewGrid={setViewGrid} />} filters={monthlyFilters} {...customProps}>
            {viewGrid ? (
                <MonthlyGrid />
            ) : (
                <MonthlyRow />
            )}
        </List>
    );
};

export default MonthlyList;