import React from 'react';
import { TextField, useRecordContext, useTranslate } from 'react-admin';
import MUILink from '@mui/material/Link';
import EyeIcon from '@mui/icons-material/RemoveRedEye';

import DateField from '../../common/fields/DateField';
import Datagrid from '../../common/Datagrid';
import PriorityField from './fields/PriorityField';
import { IconButton } from 'components/common';

const Link = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    return (
        <MUILink href={`https://redmine.kern-it.be/issues/${record.id}`} target="_blank" rel="noreferrer noopener">
            <IconButton icon={<EyeIcon />}>{translate('ra.action.show')}</IconButton>
        </MUILink>
    )
}

const IssueRow = (props) => (
    <Datagrid {...props} bulkActionButtons={false}>
        <TextField source="id" />
        <PriorityField source="priority.name" label="Priority" sortBy="priority" />
        <TextField source="status.name" label="Status" sortBy="status" />
        <TextField source="subject" />
        <DateField source="created_on" showTime />
        <Link />
    </Datagrid>
);

export default IssueRow;