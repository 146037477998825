import React from 'react';
import { TabbedForm, TextInput, DateInput, ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput, SelectInput } from 'react-admin';

import Edit from '../common/views/Edit';
import RichTextInput, { FullRichTextInputToolbar } from '../common/inputs/RichTextInput';
import ColorInput from '../common/inputs/ColorInput';
import StatusInput from './inputs/StatusInput';
import CategoryInput from './inputs/CategoryInput';

const optionText = choice => choice.name ? choice.name : '';
const userOptionText = choice => choice ? choice?.fullname : '';

const ProjectEdit = (props) => (
    <Edit {...props}>
        <TabbedForm>
            <TabbedForm.Tab label="Summary">
                <TextInput source="id" disabled />
                <TextInput source="name" />
                <TextInput source="alias" />
                <ReferenceInput source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput optionText={optionText} size="small" label="Customer" />
                </ReferenceInput>
                <CategoryInput />
                <StatusInput />
                <ReferenceArrayInput reference="users" source="user_ids">
                    <AutocompleteArrayInput optionText={userOptionText} clearOnBlur={false} label="Users" />
                </ReferenceArrayInput>
                <DateInput source="start_date" />
                <DateInput source="end_date" />
                <ColorInput sx={{ marginBottom: '16px' }} />
                <ReferenceInput source="redmine_id" reference="redmine/projects" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput label="Redmine project" />
                </ReferenceInput>
                <RichTextInput source="description" toolbar={<FullRichTextInputToolbar />} />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Links">
                <TextInput source="google_drive_url" label="Google Drive" type="url" fullWidth />
                <TextInput source="redmine_url" label="Redmine" type="url" fullWidth />
                <TextInput source="gitlab_url" label="GitLab" type="url" fullWidth />
                <TextInput source="figma_url" label="Figma" type="url" fullWidth />
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
);

export default ProjectEdit;
