import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

import { fetchTimeEntriesStats } from '../../../services/api';
import { stringToColor } from '../../../services/utils';

const boxSx = {
    height: '100%',
    width: '100%',
    '& .recharts-wrapper svg': {
        overflow: 'inherit !important'
    }
};

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{value}h</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const TimeEntryPieChart = ({ projectId, typeId, userId, startDate, endDate, freq, groupBy, disableFetch = false, ...props }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState(props.data);

    const formatData = (customData) => {
        if (customData) {
            const groupData = customData.reduce((acc, item) => {
                const foundIndex = acc.findIndex(r => r.name === item.name);
            
                if (foundIndex === -1) {
                    if (item.name) {
                        return [...acc, item];
                    }
                    return acc;
                }
            
                const updatedItem = { ...acc[foundIndex], value: acc[foundIndex].value + item.value };
                acc.splice(foundIndex, 1, updatedItem);
                return acc;
            }, []);

            setData(groupData);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (groupBy !== 'date') {
                const response = await fetchTimeEntriesStats({ project_id: projectId, type_id: typeId, user_id: userId, start_date: startDate, end_date: endDate, freq, group_by: groupBy });
                if (response?.data) {
                    formatData(response.data);
                }
            }
        };

        if (!disableFetch) {
            fetchData();
        }
    }, [projectId, typeId, userId, startDate, endDate, freq, groupBy, disableFetch]);

    useEffect(() => {
        if (props.data) {
            formatData(props.data);
        }
    }, [props.data]);

    const handleMouseEnter = (_, index) => {
        setActiveIndex(index);
    }

    if (!data || !groupBy || groupBy === 'date') {
        return null;
    }

    return (
        <Box sx={boxSx}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                        onMouseEnter={handleMouseEnter}
                        style={{ overflow: 'unset' }}
                    >
                        {data.map((record, index) => (
                            <Cell key={`cell-${index}`} fill={record.color || stringToColor(record.name)} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default TimeEntryPieChart;