import React from 'react';
import { Link, useTranslate } from 'react-admin';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import IconButton from '../../common/buttons/IconButton';

const TimerButton = () => {
    const translate = useTranslate();

    return (
        <IconButton component={Link} to="/time_entries/timer" icon={<TimerOutlinedIcon />}>{translate('Timer')}</IconButton>
    );
};

export default TimerButton;