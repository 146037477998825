import React from 'react';
import { List, TextField, SearchInput } from 'react-admin';

import Datagrid from '../common/Datagrid';
import RecordSplitButton from '../common/buttons/RecordSplitButton';

const customerFilters = [
	<SearchInput source="q" alwaysOn />
];

const CustomerList = props => (
    <List filters={customerFilters} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <RecordSplitButton />
        </Datagrid>
    </List>
);

export default CustomerList;