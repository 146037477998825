import PendingActionsIcon from '@mui/icons-material/PendingActions';

import TimeEntryTypeList from './TimeEntryTypeList';
import TimeEntryTypeEdit from './TimeEntryTypeEdit';
import TimeEntryTypeCreate from './TimeEntryTypeCreate';


const time_entry_types = {
	list: TimeEntryTypeList,
	edit: TimeEntryTypeEdit,
	create: TimeEntryTypeCreate,
	icon: PendingActionsIcon
};

export default time_entry_types;
