import UserIcon from '@mui/icons-material/People';

import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';
import UserShow from './UserShow';

const users = {
	list: UserList,
	edit: UserEdit,
	create: UserCreate,
	show: UserShow,
	icon: UserIcon
};

export default users;
