import { DateField as RADateField } from 'react-admin';

const DateField = ({
	options = { timeZone: 'UTC' },
	...props
}) => (
	<RADateField options={options} {...props} />
);

export default DateField;
