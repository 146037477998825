import TaskItemExtension from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { useTiptapEditor } from 'ra-input-rich-text';
import { ToggleButton } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';


const TaskListButton = (props) => {
    const editor = useTiptapEditor();

    const addTaskList = () => {
        editor.commands.toggleTaskList();
    };

    return (
        <ToggleButton
            aria-label="Task list"
            title="Task list"
            {...props}
            disabled={!editor?.isEditable}
            value="tasklist"
            onClick={addTaskList}
        >
            <ChecklistIcon fontSize="inherit" />
        </ToggleButton>
    );
};

const TaskItem = TaskItemExtension.configure({
    nested: true,
});

export { TaskListButton, TaskList, TaskItem };