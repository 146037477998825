import React from 'react';
import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';

const getSx = (color) => ({
	backgroundColor: color,
	width: '20px',
	height: '20px'
});

const ColorField = ({ source = 'color' }) => {
	const record = useRecordContext();

	if (!record || !source || !record[source]) {
		return null;
	}

	return (
		<Box sx={getSx(record[source])} />
	);
};

export default ColorField;