import { useState } from 'react';
import { Button, useListContext, useRefresh, useNotify, useUnselectAll, usePermissions } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

import { markRefusedLeave } from '../../../services/api';

const BulkAcceptButton = () => {
    const [loading, setLoading] = useState(false);
    const { permissions } = usePermissions();
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('leaves');

    const handleClick = () => {
        setLoading(true);
        let success = true;
        selectedIds.forEach(async id => {
            const response = await markRefusedLeave(id);
            if (!response.success) {
                success = false;
            }
        });
        if (success) {
            notify("Items updated");
            unselectAll();
        }
        else {
            notify("Error: leaves not updated", { type: "error" });
        }

        refresh();
        setLoading(false);
    };

    if (!permissions.includes('is_admin')) {
        return null;
    }

    return (
        <Button label="Refuse" onClick={handleClick} disabled={loading} color="error">
            <CloseIcon />
        </Button>
    );
};

export default BulkAcceptButton;