import React from 'react';

import { AppBar as RAAppBar, Link } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import SearchBar from './SearchBar';
import AppUserMenu from '../AppUserMenu';

const useStyles = makeStyles({
    logo: {
        maxHeight: '34px',
        marginRight: '15px'
    },
});

const AppBar = ({ logo = '/img/logo.svg', ...props }) => {
    const classes = useStyles();

    return (
        <RAAppBar {...props} color="primary" userMenu={<AppUserMenu />}>
            {logo && (
                <Link to="/">
                    <img src={logo} alt={props.title} className={classes.logo} />
                </Link>
            )}
            <Typography variant="h6" id="react-admin-title" color="inherit" />
            <SearchBar />
        </RAAppBar>
    )
};

export default AppBar;
