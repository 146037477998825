import React, { useContext } from 'react';
import { List as RAList, Pagination, CreateButton, ExportButton, TopToolbar, FilterContext, FilterButton, useResourceContext, useResourceDefinition, useListContext } from 'react-admin';

const DefaultListActions = ({ bulkActions, selectedIds, onUnselectItems, className, showCreate = true, children, ...props }) => {
	const { filters: filtersProp } = props;
	const resource = useResourceContext();
	const { hasCreate } = useResourceDefinition({ resource });
	const { displayedFilters, filterValues, exporter, showFilter, total } = useListContext();
	const filters = useContext(FilterContext) || filtersProp;

	return (
		<>
			{bulkActions && React.cloneElement(bulkActions, {
				filterValues,
				resource,
				selectedIds,
				onUnselectItems,
			})}

			{filtersProp
				? React.cloneElement(filtersProp, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: 'button',
				})
				: filters && <FilterButton />
			}
			{children}
			{showCreate ? (hasCreate && <CreateButton />) : null}
			{exporter !== false && (
				<ExportButton disabled={total === 0} resource={resource} maxResults={5000} />
			)}
		</>
	);
};

const ListActions = ({ className, ...props }) => (
	<TopToolbar className={className}>
		<DefaultListActions {...props} />
	</TopToolbar>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const List = ({
	pagination = <ListPagination />,
	perPage = 25,
	sort = { field: 'id', order: 'DESC' },
	...props
}) => (
	<RAList actions={<ListActions />} pagination={pagination} perPage={perPage} sort={sort} {...props} />
);

export default List;
export { DefaultListActions, ListActions, ListPagination };