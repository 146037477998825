import * as React from 'react';
import { Title, useGetIdentity, useAuthenticated, useDefaultTitle, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import History from '@mui/icons-material/History';

import FrogIcon from '../icons/Frog';

const QUOTES = [
    { text: "L'hérésie naît de l'oisiveté.", copyright: "Space Marine" },
    { text: "La victoire s'obtient dans le sang. Le vôtre ou celui de l'ennemi.", copyright: "Space Marine" },
    { text: "Et nous ne connaîtrons pas la peur !", copyright: "Space Marine" },
    { text: "Si un travail mérite d'être fait, il mérite qu'on en meure.", copyright: "Space Marine" },
    { text: "Le sang des martyrs est la sève de l'Imperium.", copyright: "Space Marine" },
    { text: "Le devoir ne cesse que dans la mort.", copyright: "Space Marine" },
    { text: "S'avouer vaincu serait un blasphème envers l'Empereur.", copyright: "Space Marine" },
    { text: "Les règles ne doivent jamais prendre le pas sur l'intelligence.", copyright: "Space Marine 2" },
    { text: "Un ennemi sans honneur est un ennmi vaincu d'avance.", copyright: "Space Marine 2" },
    { text: "La douleur renforce la dévotion.", copyright: "Space Marine 2" },
    { text: "Nous devons agir au nom du codex.", copyright: "Space Marine 2" },
    { text: "Le devoir ne prend fin que dans la mort.", copyright: "Space Marine 2" },
    { text: "Pas de quartier, pas de répit, PAS DE CRAINTE.", copyright: "Space Marine 2" },
    { text: "En temps de guerre, mieux vaut exécuter un innocent qu'avoir une taupe dans ses rangs.", copyright: "Rogue Trader" },
    { text: "Le succès se mesure au sang versé… Le vôtre comme celui de vos ennemis.", copyright: "Rogue Trader" },
    { text: "La grande sagesse trouve souvent sa source dans la simplicité.", copyright: "Eisenhorn" }
];
const RANDOM_QUOTE = QUOTES[Math.floor(Math.random() * QUOTES.length)];

const NotFound = props => {
    const { className, ...rest } = props;
    const translate = useTranslate();
    useAuthenticated();
    const title = useDefaultTitle();
    const { data: account } = useGetIdentity();

    return (
        <Root className={className} {...sanitizeRestProps(rest)}>
            <Title defaultTitle={title} />
            <div className={NotFoundClasses.message}>
                <FrogIcon user={account?.trigram} width={220} height={220} />
                <h1>{translate('ra.page.not_found')}</h1>
                <div>{translate('ra.message.not_found')}.</div>
                <Box sx={{ margin: '15px' }}>
                    <Typography sx={{ fontStyle: 'italic' }}>"{RANDOM_QUOTE.text}"</Typography>
                    <Typography variant="caption" sx={{}}>{RANDOM_QUOTE.copyright}</Typography>
                </Box>
            </div>
            <div className={NotFoundClasses.toolbar}>
                <Button
                    variant="contained"
                    startIcon={<History />}
                    onClick={goBack}
                >
                    {translate('ra.action.back')}
                </Button>
            </div>
        </Root>
    );
};

const sanitizeRestProps = ({ staticContext, history, location, match, ...rest }) => rest;

const PREFIX = 'RaNotFound';

export const NotFoundClasses = {
    icon: `${PREFIX}-icon`,
    message: `${PREFIX}-message`,
    toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div', { name: PREFIX, overridesResolver: (props, styles) => styles.root })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        height: '100%',
    },
    [theme.breakpoints.down('md')]: {
        height: '100vh',
        marginTop: '-3em',
    },

    [`& .${NotFoundClasses.icon}`]: {
        width: '9em',
        height: '9em',
    },

    [`& .${NotFoundClasses.message}`]: {
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
        opacity: 0.5,
        margin: '0 1em',
    },

    [`& .${NotFoundClasses.toolbar}`]: {
        textAlign: 'center',
        marginTop: '2em',
    },
}));

function goBack() {
    window.history.go(-1);
}

export default NotFound;