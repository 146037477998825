import { Sidebar as RASidebar } from 'react-admin';

const Sidebar = (props) => (
    <RASidebar
        sx={{
            '& .RaSidebar-fixed': {
                backgroundColor: 'background.paper',
                height: '100%',
                boxShadow: (theme) => theme.palette.mode === 'dark' ? 'none' : `0 1px 15px 1px ${theme.palette.divider}`
            }
        }}
        {...props}
    />
);

export default Sidebar;