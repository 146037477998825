import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';

const optionText = choice => choice?.name ? choice.name : '';

const TimeEntryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="description" multiline fullWidth rows="4" />
            <DateInput source="date" />
            <ReferenceInput source="project_id" reference="projects" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} size="small" />
            </ReferenceInput>
            <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
                <AutocompleteInput optionText={optionText} size="small" />
            </ReferenceInput>
            <NumberInput source="hours" step={0.25} min={0.25} />
            <BooleanInput source="invoiced" />
            <TextInput source="notes" multiline fullWidth rows="4" />
        </SimpleForm>
    </Edit>
);

export default TimeEntryEdit;
