import React from 'react';
import { Link, useGetList, useGetIdentity, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';

import IconCard from '../common/IconCard';

import projects from '../projects';

const MyProjectsCard = () => {
    const { data: account } = useGetIdentity();
    const { total } = useGetList(
        'projects',
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'name', order: 'ASC' },
            filter: { user_id: account?.id }
        }
    );
    const translate = useTranslate();

    if (total > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Link to={`/projects?filter={"user_id":${account?.id}}`}>
                    <IconCard
                        title={translate('My projects')}
                        value={total}
                        icon={<projects.icon />}
                    />
                </Link>
            </Grid>
        );
    }
    return null;
};

export default MyProjectsCard;