import React, { useState, useEffect } from 'react';
import { Show as RAShow, ShowBase as RAShowBase, ListButton, EditButton, useShowContext, useResourceDefinition, useTranslate, useGetResourceLabel } from 'react-admin';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import CardActions from '../layout/CardActions';
import Breadcrumb from '../layout/Breadcrumb';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center'
	}
});

const ShowActions = ({ className, children, breadcrumb, ...props }) => {
	const { resource, record } = useShowContext();
	let { hasList, hasEdit } = useResourceDefinition({ resource });
	const getResourceLabel = useGetResourceLabel();
	if (props?.hasEdit !== null && props?.hasEdit !== undefined) {
		hasEdit = props.hasEdit;
	}
	const [icon, setIcon] = useState(null);
	const translate = useTranslate();
	const classes = useStyles();

	useEffect(() => {
		async function getIcon() {
			try {
				const moduleResource = await import(`../../${resource}`);
				if (moduleResource?.default?.icon) {
					setIcon(<moduleResource.default.icon />);
				}
			} catch (e) {
				console.error(e);
			}
		}

		if (resource) {
			getIcon();
		}
	}, [resource]);

	const breadcrumbPath = [
		{ url: `/${resource}`, title: translate(`resources.${resource}.name`, { smart_count: 2, _: getResourceLabel(resource, 2) }), isActive: false, icon },
		{
			url: record ? `${resource}/${record.id}/show` : '',
			title: record ? (record.name ? record.name : record.id) : '',
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div className={classes.container}>
				{children}
				{hasList && <ListButton />}
				{hasEdit && <EditButton record={record} />}
			</div>
		</CardActions>
	);
};

// TODO: fix theme
const themeShow = theme => createTheme({
	...theme,
	components: {
		...theme.components,
		RaSimpleShowLayout: {
			styleOverrides: {
				root: {
					'& .RaSimpleShowLayout-row': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center'
					}
				}
			}
		},
		RaTabbedShowLayout: {
			styleOverrides: {
				root: {
					'& .ra-field': {
						display: 'flex !important',
						flexDirection: 'row',
						alignItems: 'center'
					}
				}
			}
		},
		RaLabeled: {
			styleOverrides: {
				root: {
					'& .RaLabeled-label': {
						minWidth: '150px'
					}
				}
			}
		}
	}
});

const Show = ({ actions = <ShowActions />, ...props }) => (
	<ThemeProvider theme={themeShow}>
		<RAShow actions={actions} {...props} />
	</ThemeProvider>
);

const ShowBase = (props) => (
	<ThemeProvider theme={themeShow}>
		<RAShowBase {...props} />
	</ThemeProvider>
);

export default Show;
export { ShowBase, ShowActions, themeShow };