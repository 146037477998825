import React, { useState, useEffect } from 'react';
import { ReferenceInput, AutocompleteInput, SelectInput, DateInput, Form, useTranslate, useGetResourceLabel, useStore, parseQueryFromLocation } from 'react-admin';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { stringify } from 'query-string';

import TimeEntryBarChart from './charts/TimeEntryBarChart';
import TimeEntryPieChart from './charts/TimeEntryPieChart';
import Breadcrumb from '../common/layout/Breadcrumb';

import { fetchTimeEntriesStats } from '../../services/api';

import time_entries from '.';

const optionText = choice => choice?.name ? choice.name : '';
const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const TimeEntryStats = () => {
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [loading, setLoading] = useState(true);

    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();
    const [params, setParams] = useStore('time_entries.statsParams', {});
    const queryFromLocation = parseQueryFromLocation(location);

    const [projectId, setProjectId] = useState(params?.filter?.project_id);
    const [customerId, setCustomerId] = useState(params?.filter?.customer_id);
    const [typeId, setTypeId] = useState(params?.filter?.type_id);
    const [userId, setUserId] = useState(params?.filter?.user_id);
    const [startDate, setStartDate] = useState(params?.filter?.start_date);
    const [endDate, setEndDate] = useState(params?.filter?.end_date);
    const [freq, setFreq] = useState(params?.filter?.freq || 'daily');
    const [groupBy, setGroupBy] = useState(params?.filter?.group_by);

    useEffect(() => {
        if (loading && Object.keys(queryFromLocation).length > 0) {
            setParams(queryFromLocation);

            if (queryFromLocation.filter?.project_id) {
                setProjectId(queryFromLocation.filter.project_id);
            }
            if (queryFromLocation.filter?.customer_id) {
                setCustomerId(queryFromLocation.filter.customer_id);
            }
            if (queryFromLocation.filter?.type_id) {
                setTypeId(queryFromLocation.filter.type_id);
            }
            if (queryFromLocation.filter?.user_id) {
                setUserId(queryFromLocation.filter.user_id);
            }
            if (queryFromLocation.filter?.start_date) {
                setStartDate(queryFromLocation.filter.start_date);
            }
            if (queryFromLocation.filter?.end_date) {
                setEndDate(queryFromLocation.filter.end_date);
            }
            if (queryFromLocation.filter?.freq) {
                setFreq(queryFromLocation.filter.freq);
            }
            if (queryFromLocation.filter?.group_by) {
                setGroupBy(queryFromLocation.filter.group_by);
            }

            setLoading(false);
        }
    }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchData = async () => {
            const filters = {
                project_id: projectId,
                customer_id: customerId,
                type_id: typeId,
                user_id: userId,
                start_date: startDate,
                end_date: endDate,
                freq,
                group_by: groupBy
            };
            const response = await fetchTimeEntriesStats(filters);
            if (response?.data) {
                setData(response.data);
                setTotal(response.total);
            }

            setParams({ filter: filters });
            navigate({ search: `?${stringify({ filter: JSON.stringify(filters) })}` });
        };

        fetchData();
    }, [projectId, customerId, typeId, userId, startDate, endDate, freq, groupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeProject = (id) => {
        setProjectId(id || null);
    };

    const handleChangeCustomer = (id) => {
        setCustomerId(id || null);
    };

    const handleChangeType = (id) => {
        setTypeId(id || null);
    };

    const handleChangeUser = (id) => {
        setUserId(id || null);
    };

    const handleChangeStartDate = (e) => {
        setStartDate(e?.target?.value || null);
    };

    const handleChangeEndDate = (e) => {
        setEndDate(e?.target?.value || null);
    };

    const handleChangeFreq = (e, value) => {
        setFreq(value);
    };

    const handleChangeGroupBy = (e) => {
        if (e?.target?.value) {
            setGroupBy(e.target.value);
        }
        else {
            setGroupBy(null);
        }
    };

    const breadcrumbPath = [
        { url: '/time_entries', title: translate('resources.time_entries.name', { smart_count: 2, _: getResourceLabel('time_entries', 2) }), isActive: false, icon: <time_entries.icon /> },
        { url: 'time_entries/stats', title: translate('Stats'), isActive: true }
    ];

    const groupByChoices = [
        { id: 'user', name: translate('User') },
        { id: 'project', name: translate('Project') },
        { id: 'type', name: translate('Type') }
    ];

    const filters = { projectId, customerId, typeId, userId, freq, groupBy, startDate, endDate };

    return (
        <>
            <Breadcrumb path={breadcrumbPath} />
            <Card sx={{ padding: '30px' }}>
                <Form defaultValues={{ project_id: projectId, customer_id: customerId, type_id: typeId, user_id: userId, start_date: startDate, end_date: endDate, freq, group_by: groupBy }}>
                    <Box display="flex" justifyContent="space-between" gap={1}>
                        <Box display="flex" gap={1}>
                            <ReferenceInput source="project_id" reference="projects" sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteInput optionText={optionText} size="small" label="Project" onChange={handleChangeProject} />
                            </ReferenceInput>
                            <ReferenceInput source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteInput optionText={optionText} size="small" label="Customer" onChange={handleChangeCustomer} />
                            </ReferenceInput>
                            <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteInput optionText={optionText} size="small" label="Type" onChange={handleChangeType} />
                            </ReferenceInput>
                            <ReferenceInput source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }}>
                                <AutocompleteInput optionText={userOptionText} size="small" label="User" onChange={handleChangeUser} />
                            </ReferenceInput>
                        </Box>
                        <SelectInput source="group_by" onChange={handleChangeGroupBy} choices={groupByChoices} sx={{ margin: 0 }} />
                    </Box>
                    <Box display="flex" gap={1}>
                        <DateInput source="start_date" onChange={handleChangeStartDate} />
                        <DateInput source="end_date" onChange={handleChangeEndDate} />
                        <ToggleButtonGroup size="small" value={freq} onChange={handleChangeFreq} exclusive sx={{ height: '40px' }}>
                            <ToggleButton value="daily">{translate('Daily')}</ToggleButton>
                            <ToggleButton value="monthly">{translate('Monthly')}</ToggleButton>
                            <ToggleButton value="yearly">{translate('Yearly')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Form>
                <Grid container>
                    <Grid item lg={9} xs={12}>
                        <Box height="400px">
                            <TimeEntryBarChart {...filters} data={data} total={total} disableFetch />
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Box height="400px" sx={{ overflow: 'unset' }}>
                            <TimeEntryPieChart {...filters} data={data} disableFetch />
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default TimeEntryStats;