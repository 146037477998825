import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, required, minLength } from 'react-admin';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import ColorInput from '../common/inputs/ColorInput';

const optionText = choice => choice.firstname || choice.lastname ? `${choice.firstname} ${choice.lastname}` : '';

const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<BooleanInput source="active" defaultValue={true} />
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<TextInput source="password" validate={[required(), minLength(8)]} />
			<TextInput source="documents_url" />
			<ColorInput sx={{ marginBottom: '16px' }} />
			<ReferenceInput source="redmine_id" reference="redmine/users" sort={{ field: 'name', order: 'ASC' }}>
				<AutocompleteInput optionText={optionText} size="small" label="Redmine user" />
			</ReferenceInput>
			<BooleanInput source="is_admin" label="Admin" />
			<BooleanInput source="is_staff" label="Staff" />
		</SimpleForm>
	</Create>
);

export default UserCreate;