import React from 'react';
import { SearchInput, NullableBooleanInput } from 'react-admin';

import List from '../common/views/List';
import UserRow from './UserRow';

const userFilters = [
	<SearchInput source="q" alwaysOn />,
	<NullableBooleanInput label="Admin" source="is_admin" alwaysOn />,
	<NullableBooleanInput label="Staff" source="is_staff" alwaysOn />,
	<NullableBooleanInput source="active" />,
];

const UserList = props => (
	<List filters={userFilters} {...props}>
		<UserRow />
	</List>
);

export default UserList;