import React from 'react';
import { Datagrid, TextField, usePermissions } from 'react-admin';

import RecordSplitButton from '../common/buttons/RecordSplitButton';
import MultilineTextField from '../common/fields/MultilineTextField';

const SplitButton = (props) => {
    const { permissions } = usePermissions();

    if (!permissions?.includes('is_admin')) {
        return null;
    }

    return (
        <RecordSplitButton {...props} hasShow={false} />
    );
};

const TimeEntryTypeRow = ({ children, ...props }) => (
    <Datagrid {...props}>
        <TextField source="id" />
        <TextField source="name" />
        <MultilineTextField source="description" />
        {children}
        <SplitButton />
    </Datagrid>
);

export default TimeEntryTypeRow;