import { Node, mergeAttributes } from '@tiptap/core';

const Iframe = Node.create({
    name: 'iframe',
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            src: {
                default: null,
            },
            frameborder: {
                default: 0,
            },
            allowfullscreen: {
                default: this.options.allowFullscreen,
                parseHTML: () => this.options.allowFullscreen,
            },
        }
    },
    parseHTML() {
        return [{
            tag: 'iframe',
        }]
    },
    renderHTML({ HTMLAttributes }) {
        return ['iframe', mergeAttributes(HTMLAttributes)];
    },
});

export { Iframe };