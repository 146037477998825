import React from 'react';
import { TextField, BooleanField, EmailField, usePermissions } from 'react-admin';
import Typography from '@mui/material/Typography';

import ResourceLinkField from '../common/fields/ResourceLinkField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import DateField from '../common/fields/DateField';
import Datagrid from '../common/Datagrid';

const FullNameField = ({ record }) => (
    <Typography variant="body2">{record.firstname} {record.lastname}</Typography>
);


const UserRow = props => {
    const { permissions } = usePermissions();

    return (
        <Datagrid>
            <ResourceLinkField label="Id" resource="users">
                <TextField source="id" />
            </ResourceLinkField>
            <ResourceLinkField label="Name" resource="users">
                <FullNameField />
            </ResourceLinkField>
            <TextField source="trigram" label="" />
            <EmailField source="email" />
            <DateField source="last_login_at" showTime />
            <BooleanField source="active" />
            {permissions?.includes('is_admin') && <RecordSplitButton {...props} />}
        </Datagrid>
    );
};

export default UserRow;