import React from 'react';
import { usePermissions, useRecordContext } from 'react-admin';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import { ShowBase, ShowActions } from '../common/views/Show';
import MonthlyCard from './MonthlyCard';

const NOW = moment();

const MonthlyShowActions = (props) => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const hasPermission = permissions?.includes('is_admin') && NOW.diff(moment(record?.created_at), 'days') <= 35;

    return (
        <ShowActions
            {...props}
            hasEdit={hasPermission}
        />
    );
};

const Content = () => {
    const record = useRecordContext();

    return (
        <MonthlyCard record={record} actions={false} showWorkloadColor={false} />
    )
};

const MonthlyShow = () => (
    <ShowBase>
        <MonthlyShowActions />
        <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
            <Grid item xs={12}>
                <Content />
            </Grid>
        </Grid>
    </ShowBase>
);

export default MonthlyShow;