import React from 'react';
import { UserMenu, MenuItemLink, Logout } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';


const AppUserMenu = props => (
    <UserMenu {...props}>
        <MenuItemLink
            to="/account"
            primaryText="Account"
            leftIcon={<SettingsIcon />}
        />
        <Logout />
    </UserMenu>
);

export default AppUserMenu;