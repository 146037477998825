import React from 'react';
import {
    DefaultEditorOptions,
    RichTextInput as RARichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    ColorButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
} from 'ra-input-rich-text';
import { ToggleButtonGroup } from '@mui/material';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';

import { Image, ImageButton } from './Image';
import { Youtube, VideoButton } from './Video';
import { HorizontalRuleButton } from './HorizontalRule';
import { Iframe } from './Iframe';
import { TaskListButton, TaskList, TaskItem } from './TaskList';

const SmallRichTextInputToolbar = ({ size }) => (
    <RichTextInputToolbar>
        <LevelSelect size={size} />
        <FormatButtons size={size} />
        <AlignmentButtons size={size} />
        <ListButtons size={size} />
        <TaskListButton size={size} />
        <LinkButtons size={size} />
        <ClearButtons size={size} />
    </RichTextInputToolbar>
);

const FullRichTextInputToolbar = ({ size }) => (
    <RichTextInputToolbar>
        <LevelSelect size={size} />
        <FormatButtons size={size} />
        <ColorButtons size={size} />
        <AlignmentButtons size={size} />
        <ListButtons size={size} />
        <TaskListButton size={size} />
        <LinkButtons size={size} />
        <ToggleButtonGroup>
            <ImageButton size={size} />
            <VideoButton size={size} />
        </ToggleButtonGroup>
        <QuoteButtons size={size} />
        <HorizontalRuleButton size={size} />
        <ClearButtons size={size} />
    </RichTextInputToolbar>
);

const RichTextInput = ({ size, ...props }) => (
    <RARichTextInput
        editorOptions={EditorOptions}
        toolbar={<SmallRichTextInputToolbar size={size} />}
        fullWidth
        {...props}
    />
);

export const EditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        StarterKit,
        Underline,
        Link,
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        Youtube,
        Iframe,
        Image,
        TaskList,
        TaskItem
    ],
};

export default RichTextInput;
export { SmallRichTextInputToolbar, FullRichTextInputToolbar };