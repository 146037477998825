import React from 'react';
import { TextInput, useGetList, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import RichTextInput from '../common/inputs/RichTextInput';
import MultilineTextField from '../common/fields/MultilineTextField';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';

const drawerSx = {
    width: 400,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: 400,
        boxSizing: 'border-box',
        padding: '15px',
        paddingTop: '55px'
    },
    '@media (max-width: 760px)': {
        display: 'none'
    }
};

const MonthlyCreate = (props) => {
    const translate = useTranslate();
    const { data: lastMonthlies } = useGetList(
        'monthlies',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'created_at', order: 'DESC' }
        }
    );

    return (
        <Box display="flex">
            <Box sx={{ flexGrow: 1, marginBottom: '15px' }}>
                <Create {...props}>
                    <SimpleForm>
                        <RichTextInput source="priorities" />
                        <RichTextInput source="tasks" />
                        <TextInput source="notes" multiline fullWidth rows="4" sx={{ '&&': { minWidth: '100%!important' } }} />
                    </SimpleForm>
                </Create>
            </Box>
            {lastMonthlies?.length > 0 && (
                <Drawer open variant="persistent" anchor="right" sx={drawerSx}>
                    <Typography variant="h5">{translate('Last monthly')} <DateField source="created_at" record={lastMonthlies[0]} /></Typography>

                    <Box sx={{ margin: '15px 0' }}>
                        <Typography variant="h6">{translate('Priorities')}</Typography>
                        <RichTextField source="priorities" record={lastMonthlies[0]} />
                    </Box>

                    <Divider />

                    <Box sx={{ margin: '15px 0' }}>
                        <Typography variant="h6">{translate('Tasks')}</Typography>
                        <RichTextField source="tasks" record={lastMonthlies[0]} />
                    </Box>

                    {lastMonthlies[0].notes && (
                        <>
                            <Divider />
                            <MultilineTextField source="notes" record={lastMonthlies[0]} />
                        </>
                    )}
                </Drawer>
            )}
        </Box>
    );
};

export default MonthlyCreate;
