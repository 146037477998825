import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceArrayField, ReferenceField, useRecordContext } from 'react-admin';
import Grid from '@mui/material/Grid';
import MUILink from '@mui/material/Link';
import Box from '@mui/material/Box';

import Show from '../common/views/Show';
import List from '../common/views/List';
import ColorField from '../common/fields/ColorField';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';
import IconCard from '../common/IconCard';
import GoogleDriveIcon from '../common/icons/GoogleDrive';
import RedmineIcon from '../common/icons/Redmine';
import GitLabIcon from '../common/icons/GitLab';
import FigmaIcon from '../common/icons/Figma';
import UserRow from '../users/UserRow';
import StatusField from './fields/StatusField';
import CategoryField from './fields/CategoryField';
import IssueRow from 'components/redmine/issues/IssueRow';


const UserArrayField = (props) => (
	<ReferenceArrayField {...props} reference="users" source="user_ids">
		<UserRow />
	</ReferenceArrayField>
);

const Link = (props) => (
	<MUILink {...props} target="_blank" rel="noreferrer noopener" />
);

const LinksGrid = () => {
	const record = useRecordContext();

	return (
		<Grid container spacing={2} sx={{ margin: '10px 0' }}>
			{record.google_drive_url && (
				<Grid item lg={2} xl={2} sm={6} xs={12}>
					<Link href={record.google_drive_url}>
						<IconCard
							title='Google Drive'
							icon={<GoogleDriveIcon />}
							variant="rounded"
							size="small"
						/>
					</Link>
				</Grid>
			)}
			{record.redmine_url && (
				<Grid item lg={2} xl={2} sm={6} xs={12}>
					<Link href={record.redmine_url}>
						<IconCard
							title='Redmine'
							icon={<RedmineIcon />}
							variant="rounded"
							size="small"
							color="#CE332C"
						/>
					</Link>
				</Grid>
			)}
			{record.gitlab_url && (
				<Grid item lg={2} xl={2} sm={6} xs={12}>
					<Link href={record.gitlab_url}>
						<IconCard
							title='GitLab'
							icon={<GitLabIcon />}
							variant="rounded"
							size="small"
							color="#F56A25"
						/>
					</Link>
				</Grid>
			)}
			{record.figma_url && (
				<Grid item lg={2} xl={2} sm={6} xs={12}>
					<Link href={record.figma_url}>
						<IconCard
							title='Figma'
							icon={<FigmaIcon />}
							variant="rounded"
							size="small"
						/>
					</Link>
				</Grid>
			)}
		</Grid>
	);
};

const IssueList = () => {
	const record = useRecordContext();

	if (!record && !record.redmine_id) {
		return null;
	}

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start">
			{record?.redmine_url && (
				<Link href={record.redmine_url} sx={{ marginBottom: '15px' }}>
					<IconCard
						title='Redmine'
						icon={<RedmineIcon />}
						variant="rounded"
						size="small"
						color="#CE332C"
					/>
				</Link>
			)}
			<List
				resource="redmine/issues"
				filter={{ project_id: record.id, status: 'open' }}
				filters={null}
				exporter={false}
				actions={false}
				empty={false}
				sx={{ '& .RaList-content': { boxShadow: 'none !important', border: 'none' } }}
				title=" "
			>
				<IssueRow />
			</List>
		</Box>
	);
};

const Tabs = () => {
	const record = useRecordContext();

	return (
		<TabbedShowLayout>
			<Tab label="Summary">
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="alias" />
				<ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<CategoryField source="category" />
				<DateField source="start_date" />
				<DateField source="end_date" />
				<StatusField source="status" />
				<ColorField source="color" />
				<RichTextField source="description" />
				<LinksGrid label={false} />
			</Tab>
			<Tab label="Users">
				<UserArrayField label={false} />
			</Tab>
			{record?.redmine_id && (
				<Tab label="Redmine">
					<IssueList label={false} />
				</Tab>
			)}
		</TabbedShowLayout>
	);
};

const ProjectShow = props => (
	<Show {...props}>
		<Tabs />
	</Show>
);

export default ProjectShow;