import { FunctionField, useRecordContext } from 'react-admin';

const DurationField = ({ source = 'duration', ...props }) => {
    const record = useRecordContext();

    if (!record || !record[source]) {
        return null;
    }
    return (
        <FunctionField {...props} render={record => `${record[source]}j`} />
    );
};

export default DurationField;