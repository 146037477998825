import { Button, useListContext, useUpdateMany, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import ReceiptIcon from '@mui/icons-material/Receipt';

const BulkInvoicedButton = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('time_entries');
    const [updateMany, { isPending }] = useUpdateMany();

    const handleClick = () => {
        updateMany(
            "time_entries",
            { ids: selectedIds, data: { invoiced: true } },
            {
                onSuccess: () => {
                    notify("Items updated");
                    unselectAll();
                },
                onError: () => {
                    notify("Error: time entries not updated", { type: "error" });
                    refresh();
                },
            }
        );
    };

    return (
        <Button label="Set invoiced" onClick={handleClick} disabled={isPending}>
            <ReceiptIcon />
        </Button>
    );
};

export default BulkInvoicedButton;