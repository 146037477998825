import React from 'react';
import { useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';

import MultilineTextField from './MultilineTextField';

const btnSx = {
    padding: '2px',
    minWidth: 'auto'
};

const TooltipTextField = ({ source, options = { multiline: true }, ...props }) => {
    const record = useRecordContext();
    
    if (!record || !record[source]) {
        return null;
    }

    return (
        <Tooltip title={<MultilineTextField record={record} source={source} {...props} />}>
            <Button size="small" sx={btnSx}>
                <MessageIcon />
            </Button>
        </Tooltip>
    );
};

export default TooltipTextField;
