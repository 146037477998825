import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import ChipField from '../../common/fields/ChipField';


const CategoryField = ({ source = 'category', size = 'small', ...props }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) {
        return null;
    }
    const category = record[source];
    let sx = {};

    if (!category) {
        return null;
    }

    const categories = {
        'application': translate('Application'),
        'web': translate('Web'),
        'design': translate('Design'),
        'other': translate('Other')
    };

    return (
        <ChipField {...props} size={size} source={category} record={categories} sx={sx} />
    );
};

export default CategoryField;
