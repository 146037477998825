import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import LeaveList from './LeaveList';
import LeaveEdit from './LeaveEdit';
import LeaveCreate from './LeaveCreate';
import LeaveShow from './LeaveShow';


const leaves = {
	list: LeaveList,
	edit: LeaveEdit,
	create: LeaveCreate,
	show: LeaveShow,
	icon: EventAvailableIcon
};

export default leaves;
