import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'active', name: 'Active' },
	{ id: 'pending', name: 'Pending' },
	{ id: 'maintenance', name: 'Maintenance' },
	{ id: 'cancelled', name: 'Cancelled' },
];

const StatusInput = ({ classes, source = 'status', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusInput;
