import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'application', name: 'Application' },
	{ id: 'web', name: 'Web' },
	{ id: 'design', name: 'Design' },
	{ id: 'other', name: 'Other' },
];

const CategoryInput = ({ classes, source = 'category', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default CategoryInput;
