import AccessTimeIcon from '@mui/icons-material/AccessTime';

import TimeEntryList from './TimeEntryList';
import TimeEntryEdit from './TimeEntryEdit';
import TimeEntryBulkCreate from './TimeEntryBulkCreate';


const time_entries = {
	list: TimeEntryList,
	edit: TimeEntryEdit,
	create: TimeEntryBulkCreate,
	icon: AccessTimeIcon
};

export default time_entries;
