import React, { useState } from 'react';
import { FieldTitle, useInput, useRecordContext } from 'react-admin';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { SketchPicker } from 'react-color';


const sx = {
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	swatch: {
		padding: '5px',
		background: '#fff',
		borderRadius: '1px',
		boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
		display: 'inline-block',
		cursor: 'pointer',
		marginLeft: '15px'
	},
	popover: {
		position: 'absolute',
		zIndex: '2',
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	},
};

const getColorSx = (color) => {
	if (color) {
		return {
			backgroundColor: color,
			width: '36px',
			height: '14px',
			borderRadius: '2px',
		}
	}
};

const ColorInput = ({ source = 'color', className, ...props }) => {
	const record = useRecordContext();
	const [show, setShow] = useState(false);
	const { field, fieldState: { isTouched, error }, isRequired } = useInput({ source, ...props });
	const { resource, label, variant } = props;

	const handleChange = ({ hex }) => {
		field.onChange(hex);
	};

	const handleClick = () => {
		setShow(currentShow => !currentShow);
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<>
			<Box sx={sx.container}>
				<TextField
					{...field}
					label={
						<FieldTitle
							label={label}
							source={source}
							resource={resource}
							isRequired={isRequired}
						/>
					}
					onChange={field.onChange}
					onFocus={handleClick}
					error={!!(isTouched && error)}
					helperText={isTouched && error}
					required={isRequired}
					variant={variant}
					className={className}
					size="small"
					record={record}
					sx={props.sx}
				/>
				{field?.value && (
					<Box sx={sx.swatch} onClick={handleClick}>
						<Box sx={getColorSx(field.value)} />
					</Box>
				)}
			</Box>
			{show && (
				<Box sx={sx.popover}>
					<Box sx={sx.cover} onClick={handleClose} />
					<SketchPicker color={field.value} onChange={handleChange} />
				</Box>
			)}
		</>
	);
};

export default ColorInput;