import React from 'react';
import { useTiptapEditor, } from 'ra-input-rich-text';
import Remove from '@mui/icons-material/Remove';
import { ToggleButton } from '@mui/material';

const HorizontalRuleButton = ({ ...props }) => {
    const editor = useTiptapEditor();

    const handleClick = () => {
        editor.chain().focus().setHorizontalRule().run();
    };

    return (
        <ToggleButton
            aria-label="Add an horizontal rule"
            title="Add an horizontal rule"
            value="hr"
            onClick={handleClick}
            selected={editor && editor.isActive('horizontalRule')}
            {...props}
        >
            <Remove fontSize="inherit" />
        </ToggleButton>
    );
};

export { HorizontalRuleButton };