import React, { useEffect, useState } from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, Form, ListButton, CreateButton, useTranslate, useGetResourceLabel, useCreate } from 'react-admin';
import { Box, Card, Button, Dialog, DialogContent, Typography } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import { useStopwatch } from 'react-timer-hook';
import moment from 'moment';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';

import Breadcrumb from '../common/layout/Breadcrumb';
import HelpButton from './buttons/HelpButton';

import time_entries from '.';

const optionText = choice => choice?.name ? choice.name : '';

const formSx = {
    display: 'flex',
    gap: '5px',
    marginBottom: '15px',
    flexWrap: 'wrap',
    width: '100%',
    '& .MuiFormControl-root, && .MuiAutocomplete-root': {
        minWidth: '180px !important',
        height: '100%',
        maxHeight: '65px',
        '.MuiInputBase-root': {
            height: '100%',
        },
        '&.ra-input-description': {
            flex: 1
        },
        '&.ra-input-notes': {
            flex: 1
        },
        '@media (max-width: 701px)': {
            height: 'auto',
            maxHeight: 'none',
            width: '100%',
            maxWidth: 'none'
        }
    }
};

const buttonsSx = {
    'success': {
        backgroundColor: green[200],
        '&:hover': { backgroundColor: green[300] }
    },
    'warning': {
        backgroundColor: orange[200],
        '&:hover': { backgroundColor: orange[300] }
    },
    'error': {
        backgroundColor: red[200],
        '&:hover': { backgroundColor: red[300] }
    }
};

const convertSecondsToHours = (value) => {
    const hours = Math.round((value / 3600) * 4) / 4;

    if (hours < 0.25) {
        return 0.25;
    }

    return hours;
};

const Timer = ({ onStart, onStop, onPause, setIsRunning, isPaused, setIsPaused, textStart = '', textStop = '', offsetTimestamp, ...props }) => {
    const [showStopDialog, setShowStopDialog] = useState(false);
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        pause,
        start,
        reset
    } = useStopwatch({ offsetTimestamp, autoStart: props.isRunning });
    const translate = useTranslate();
    const formattedHours = convertSecondsToHours(totalSeconds);

    useEffect(() => {
        setIsRunning(isRunning);
    }, [isRunning, setIsRunning]);

    const handleStart = () => {
        if (onStart) {
            onStart();
        }

        reset();
    };

    const handleStop = () => {
        if (onStop) {
            onStop(formattedHours);
        }

        reset();
        pause();
        handleCloseStopDialog();
        setIsPaused(false);
    };

    const handlePause = () => {
        if (onPause) {
            onPause();
        }
        pause();
        setIsPaused(true);
    };

    const handleResume = () => {
        if (onPause) {
            onPause();
        }
        start();
        setIsPaused(false);
    };

    const handleOpenStopDialog = () => {
        setShowStopDialog(true);
    };

    const handleCloseStopDialog = () => {
        setShowStopDialog(false);
    };

    return (
        <Box pb={3} sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
            {(isRunning || isPaused) ? (
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: '2fr 1fr' }}>
                    <Button variant="contained" size="large" color="error" onClick={handleOpenStopDialog} sx={buttonsSx.error}><StopIcon />{textStop}</Button>
                    {isPaused ? (
                        <Button variant="contained" size="large" color="success" onClick={handleResume} sx={buttonsSx.success}><PlayIcon /></Button>
                    ) : (
                        <Button variant="contained" size="large" color="warning" onClick={handlePause} sx={buttonsSx.warning}><PauseIcon /></Button>
                    )}
                </Box>
            ) : (
                <Button variant="contained" size="large" color="success" onClick={handleStart} sx={buttonsSx.success}><PlayIcon />{textStart}</Button>
            )}
            <Card variant="outlined" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: '15px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100%' }}>
                    <span>{String(days * 24 + hours).padStart(2, '0')}</span>:<span>{String(minutes).padStart(2, '0')}</span>:<span>{String(seconds).padStart(2, '0')}</span>
                </Box>
                <Box sx={{ padding: '0 15px', borderLeft: theme => `1px solid ${theme.palette.divider}`, height: '100%', display: 'flex', alignItems: 'center' }}>
                    {totalSeconds > 0 ? `${new Intl.NumberFormat('fr').format(formattedHours)}h` : '0h'}
                </Box>
            </Card>

            <Dialog fullWidth open={showStopDialog} onClose={handleCloseStopDialog}>
                <DialogContent>
                    <Typography>{translate('Stop time entry?')}</Typography>
                    <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                        <Button variant="contained" onClick={handleStop}>{translate('OK')}</Button>
                        <Button variant="outlined" onClick={handleCloseStopDialog}>{translate('ra.action.cancel')}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const TimeEntryTimer = () => {
    const [timerReady, setTimerReady] = useState(true); // TODO: set to false when backend done
    const [offsetTimer, setOffsetTimer] = useState();
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const [description, setDescription] = useState();
    const [projectId, setProjectId] = useState();
    const [typeId, setTypeId] = useState();
    const [notes, setNotes] = useState();

    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const [createTimeEntry] = useCreate('time_entries');

    // TODO: Uncomment when backend done
    // useEffect(() => {
    //     const fetchLastTimeEntry = async () => {
    //         const response = await fetchLastTimeEntry();
    //         if (['in_progress', 'paused'].includes(response?.status)) {
    //             const now = DateTime.now().toUTC();
    //             const checkInDate = DateTime.fromISO(response.check_in_at, { zone: 'utc' });
    //             const diff = now.ts - checkInDate.ts;
    //             let offset = now.ts + diff - response.pause_duration * 1000;

    //             if (response.is_paused) {
    //                 const lastPausedDate = DateTime.fromISO(response.last_paused_at, { zone: 'utc' });
    //                 const pauseDuration = (now.ts - lastPausedDate.ts);
    //                 setIsPaused(response.is_paused);
    //                 offset -= pauseDuration;
    //             }
    //             else {
    //                 setIsTimerRunning(true);
    //             }
    //             setOffsetTimer(offset);
    //         }
    //         setTimerReady(true);
    //     };

    //     fetchLastTimeEntry();
    // }, []);

    const handleStart = async () => {
        // TODO: add api call to start time entry timer
        // await startTimeEntryTimer();
    };

    const handleStop = async (hours) => {
        // TODO: add api call to stop time entry timer
        // await stopTimeEntryTimer();
        setOffsetTimer(null);

        // TODO: remove when backend done
        const data = {
            date: moment().format('YYYY-MM-DD'),
            hours,
            description,
            project_id: projectId,
            type_id: typeId,
            notes
        };
        createTimeEntry('time_entries', { data });
    };

    const handlePause = async () => {
        // TODO: add api call to pause
        // await pauseTimeEntry();
    };

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };

    const handleChangeProject = (id) => {
        setProjectId(id || null);
    };

    const handleChangeType = (id) => {
        setTypeId(id || null);
    };

    const handleChangeNotes = (e) => {
        setNotes(e.target.value);
    };

    const breadcrumbPath = [
        { url: '/time_entries', title: translate('resources.time_entries.name', { smart_count: 2, _: getResourceLabel('time_entries', 2) }), isActive: false, icon: <time_entries.icon /> },
        { url: 'time_entries/timer', title: translate('Timer'), isActive: true }
    ];

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Breadcrumb path={breadcrumbPath} />
                <Box>
                    <HelpButton />
                    <CreateButton resource="time_entries" />
                    <ListButton resource="time_entries" />
                </Box>
            </Box>
            <Card sx={{ padding: '30px' }}>
                <Form>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Box sx={formSx}>
                                <TextInput source="description" multiline rows="2" helperText={false} onChange={handleChangeDescription} />
                                <ReferenceInput source="project_id" reference="projects" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={optionText} helperText={false} onChange={handleChangeProject} />
                                </ReferenceInput>
                                <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={optionText} helperText={false} onChange={handleChangeType} />
                                </ReferenceInput>
                                <TextInput source="notes" multiline rows="2" helperText={false} onChange={handleChangeNotes} />
                            </Box>
                        </Box>
                        {timerReady && (
                            <Timer
                                onStart={handleStart}
                                onStop={handleStop}
                                onPause={handlePause}
                                isRunning={isTimerRunning}
                                setIsRunning={setIsTimerRunning}
                                isPaused={isPaused}
                                setIsPaused={setIsPaused}
                                offsetTimestamp={offsetTimer}
                            />
                        )}
                    </Box>
                </Form>
            </Card>
        </>
    );
};

export default TimeEntryTimer;