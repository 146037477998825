import React from 'react';
import { TextField, useRecordContext, usePermissions, useGetIdentity } from 'react-admin';
import moment from 'moment';

import Datagrid from '../../common/Datagrid';
import RecordSplitButton from '../../common/buttons/RecordSplitButton';
import ResourceLinkField from '../../common/fields/ResourceLinkField';
import HumanDateField from '../../common/fields/HumanDateField';
import WorkloadField from '../fields/WorkloadField';

const NOW = moment();

const SplitButton = (props) => {
    const record = useRecordContext();
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const hasPermission = permissions?.includes('is_admin') || (NOW.diff(moment(record?.created_at), 'days') <= 14 && record?.user_id === account?.id);

    return (
        <RecordSplitButton
            {...props}
            hasEdit={hasPermission}
            hasDelete={hasPermission}
        />
    );
};

const WeeklyRow = props => (
    <Datagrid {...props}>
        <ResourceLinkField label="Id" resource="weeklies" sortBy="id">
            <TextField source="id" />
        </ResourceLinkField>
        <ResourceLinkField label="User" source="user" resource="users" sortBy="user_id">
            <TextField source="fullname" />
        </ResourceLinkField>
        <WorkloadField />
        <HumanDateField source="created_at" />
        <SplitButton />
    </Datagrid>
);

export default WeeklyRow;