import React from 'react';
import { List as RAList, Filter, TextInput } from 'react-admin';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ListActions, ListPagination } from './List';

const theme = theme => createTheme({
	...theme,
	overrides: {
		MuiCard: {
			root: {
				boxShadow: 'none'
			}
		}
	}
});

const SearchFilter = props => (
	<Filter {...props} variant="outlined">
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const List = ({
	actions = <ListActions />,
	pagination = <ListPagination />,
	perPage = 25,
	...props
}) => (
	<RAList actions={actions} pagination={pagination} perPage={perPage} {...props} />
);

const CustomList = ({ ...props }) => (
	<ThemeProvider theme={theme}>
		<List
			hasCreate={false}
			hasEdit={false}
			hasList={false}
			hasShow={false}
			filters={<SearchFilter />}
			{...props}
		/>
	</ThemeProvider>
);

export default CustomList;