import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, usePermissions } from 'react-admin';
import moment from 'moment';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import TypeInput from './inputs/TypeInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const validateLeave = (values) => {
    const errors = {};

    if (!moment(values.start_date).isSame(values.end_date, 'month')) {
        errors.start_date = 'Must be from the same month';
        errors.end_date = 'Must be from the same month';
    }

    return errors;
};

const LeaveEdit = (props) => {
    const { permissions } = usePermissions();

    return (
        <Edit {...props}>
            <SimpleForm validate={validateLeave}>
                <TextInput source="id" disabled />

                <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText={userOptionText} size="small" disabled={!permissions?.includes('is_admin')} />
                </ReferenceInput>
                <TypeInput />
                <DateInput source="start_date" />
                <DateInput source="end_date" />
                <NumberInput source="duration" label="Number of days" step={0.5} min={0.5} />
                <TextInput source="description" multiline fullWidth rows="4" />
            </SimpleForm>
        </Edit>
    );
};

export default LeaveEdit;