import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';


const customers = {
	list: CustomerList,
	edit: CustomerEdit,
	create: CustomerCreate,
	icon: AssignmentIndIcon
};

export default customers;
