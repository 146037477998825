import React from 'react';
import { TextInput } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';

const CustomerEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="short_name" />
            <TextInput source="description" multiline fullWidth rows="4" />
        </SimpleForm>
    </Edit>
);

export default CustomerEdit;
