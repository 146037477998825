import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
	listItem: {
		paddingLeft: '1rem',
	},
	listItemText: {
		paddingLeft: 2,
		fontSize: '1rem',
	},
	sidebarIsOpen: {
		paddingLeft: 25,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
	},
	sidebarIsClosed: {
		paddingLeft: 0,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
	},
});

const SubMenu = ({
	handleToggle,
	sidebarIsOpen,
	isOpen,
	name,
	icon = <ArrowRightIcon />,
	children
}) => {
	const classes = useStyles();

	return (
		<Fragment>
			<ListItem
				dense
				button
				onClick={handleToggle}
				className={classes.listItem}
			>
				<ListItemIcon>{isOpen ? <ExpandMoreIcon /> : icon}</ListItemIcon>
				<ListItemText
					inset
					primary={isOpen ? name : ''}
					secondary={isOpen ? '' : name}
					className={classes.listItemText}
				/>
			</ListItem>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List
					dense
					component="div"
					disablePadding
					className={
						sidebarIsOpen
							? classes.sidebarIsOpen
							: classes.sidebarIsClosed
					}
				>
					{children}
				</List>
				<Divider />
			</Collapse>
		</Fragment>
	);
};

export default SubMenu;