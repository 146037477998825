import React from 'react';
import { TextField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

import Datagrid from '../../common/Datagrid';
import RecordSplitButton from '../../common/buttons/RecordSplitButton';
import ResourceLinkField from '../../common/fields/ResourceLinkField';
import StatusField from '../fields/StatusField';
import CategoryField from '../fields/CategoryField';


const ProjectRow = props => (
    <Datagrid {...props}>
        <ResourceLinkField label="Id" resource="projects" sortBy="id">
            <TextField source="id" />
        </ResourceLinkField>
        <ResourceLinkField label="Name" resource="projects" sortBy="name">
            <TextField source="name" />
        </ResourceLinkField>
        <ResourceLinkField label="Customer" source="customer" resource="customers" sortBy="customer_id">
            <TextField source="name" />
        </ResourceLinkField>
        <ReferenceArrayField source="user_ids" reference="users" label="Users">
            <SingleFieldList sx={{ gap: '4px' }}>
                <ChipField source="trigram" variant="outlined" size="small" />
            </SingleFieldList>
        </ReferenceArrayField>
        <CategoryField />
        <StatusField />
        <RecordSplitButton />
    </Datagrid>
);

export default ProjectRow;