import React from 'react';
import { Link, useRecordContext, useCreatePath } from 'react-admin';


export const ResourceLinkField = ({ source, type = 'show', resource, children }) => {
	const record = useRecordContext();
	const createPath = useCreatePath();
	const data = source ? record[source] : record;

	if (!data) {
		return null;
	}

	return (
		<Link to={createPath({ resource, type, id: data?.id })}>
			{React.cloneElement(children, { record: data })}
		</Link>
	);
};

export default ResourceLinkField;