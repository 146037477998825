import React from 'react';
import { List, SearchInput, DateInput, ReferenceInput, AutocompleteInput, NullableBooleanInput, TopToolbar, Link, CreateButton, downloadCSV, useTranslate } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { DefaultListActions } from '../common/views/List';
import IconButton from '../common/buttons/IconButton';
import SplitButton from '../common/buttons/SplitButton';
import TimeEntryRow from './TimeEntryRow';
import ImportButton from './buttons/ImportButton';
import TimerButton from './buttons/TimerButton';

const optionText = choice => choice?.name ? choice.name : '';
const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const exporter = records => {
    const recordsForExport = records.map(record => {
        let recordForExport = { date: record.date, description: record.description, hours: record.hours, invoiced: record.invoiced, notes: record.notes };
        recordForExport.user = record?.user?.trigram;
        recordForExport.project = record?.project?.name;
        recordForExport.type = record?.type?.name;
        return recordForExport;
    });
    jsonExport(recordsForExport, {
        headers: ['project', 'date', 'user', 'description', 'type', 'hours', 'invoiced', 'notes']
    }, (err, csv) => {
        downloadCSV(csv, `time_entries-${new Date().toLocaleDateString('en-CA')}`);
    });
};

const timeEntryFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="project_id" reference="projects" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={optionText} size="small" label="Project" />
    </ReferenceInput>,
    <ReferenceInput source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={userOptionText} size="small" label="User" />
    </ReferenceInput>,
    <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={optionText} size="small" />
    </ReferenceInput>,
    <DateInput source="start_date" />,
    <DateInput source="end_date" />,
    <NullableBooleanInput source="invoiced" />
];

const TimeEntryListActions = (props) => {
    const translate = useTranslate();

    return (
        <TopToolbar>
            <DefaultListActions showCreate={false}>
                <IconButton component={Link} to="/time_entries/stats" icon={<QueryStatsIcon />}>{translate('Stats')}</IconButton>
                <SplitButton>
                    <><CreateButton /></>
                    <TimerButton />
                    <ImportButton {...props} />
                </SplitButton>
            </DefaultListActions>
        </TopToolbar>
    );
};

const TimeEntryList = props => (
    <List filters={timeEntryFilters} sort={{ field: 'date', order: 'DESC' }} exporter={exporter} actions={<TimeEntryListActions />} perPage={50} {...props}>
        <TimeEntryRow />
    </List>
);

export default TimeEntryList;