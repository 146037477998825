import React, { useState, useEffect, useRef } from 'react';
import { useGetList, useSidebarState, useUpdate, useGetIdentity, useLocaleState } from 'react-admin';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment';

import LeaveEdit from '../LeaveEdit';
import LeaveCreate from '../LeaveCreate';

const formSx = { '& .MuiPaper-root': { border: 'none' } };
const cardSx = (theme) => ({
    padding: '20px',
    marginTop: '15px',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '& .fc-toolbar-title': {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
    },
    '& .fc-button': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.action.active,
        borderColor: theme.palette.divider,
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        textTransform: theme.typography.button.textTransform,
    },
    '& .fc-button-primary:disabled': {
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.action.active,
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.action.active
        }
    },
    '& .fc-button-group .fc-button': {
        borderColor: theme.palette.divider,
        color: theme.palette.action.active,
    },
    '& .fc-button:hover': {
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
    },
    '& .fc-button-primary:not(:disabled):active': {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.text.primary,
    },
    '& .fc-button-group .fc-button-primary:not(:disabled):active': {
        borderColor: theme.palette.divider,
    },
    '& .fc-button-primary:not(:disabled).fc-button-active': {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        color: theme.palette.action.active,
    },
    '& .fc-button-group .fc-button-primary:not(:disabled).fc-button-active': {
        borderColor: theme.palette.divider,
        color: theme.palette.text.secondary,
    }
});

const LeaveCalendar = () => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showDialogCreate, setShowDialogCreate] = useState(false);
    const [showDialogEdit, setShowDialogEdit] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [defaultValuesLeave, setDefaultValuesLeave] = useState();
    const [sidebarIsOpen] = useSidebarState();
    const calendarRef = useRef(null);
    const { data: account } = useGetIdentity();
    const [updateLeave] = useUpdate();
    const [locale] = useLocaleState();
    const { data } = useGetList(
        'leaves',
        {
            pagination: { page: 1, perPage: 300 },
            sort: { field: 'start_date', order: 'ASC' },
            filter: { start_date: startDate, end_date: endDate }
        }
    );

    const isFirstRender = useRef(true);
    useEffect(() => {
        isFirstRender.current = false;
    }, []);


    useEffect(() => {
        if (!isFirstRender.current) {
            setTimeout(() => {
                if (calendarRef.current) {
                    calendarRef.current.getApi().updateSize();
                }
            }, 300);
        }
    }, [sidebarIsOpen]);

    const handleOpenCreate = (defaultValues) => {
        setShowDialogCreate(true);
        setDefaultValuesLeave(defaultValues);
    };

    const handleCloseCreate = () => {
        setShowDialogCreate(false);
        setDefaultValuesLeave(null);
    };

    const handleOpenEdit = (id) => {
        setShowDialogEdit(true);
        setCurrentId(id);
    };

    const handleCloseEdit = () => {
        setShowDialogEdit(false);
        setCurrentId(null);
    };

    const convertToEvent = (record) => {
        let color = record?.user?.color;
        if (record?.status === 'pending') {
            color += '55';
        }

        const editable = record?.status === 'pending' && record?.user_id === account?.id;
        const end = moment(record?.end_date).add(1, 'days').format('YYYY-MM-DD');
        return {
            id: record?.id,
            title: editable ? `${record?.user?.trigram}${record.duration ? ` - ${record?.duration}j` : ''}` : record?.user?.trigram,
            start: record?.start_date,
            end,
            backgroundColor: color,
            borderColor: record?.user?.color,
            editable,
            extendedProps: {
                record,
                editable
            }
        }
    };

    const handleDatesSet = (dateInfo) => {
        const start = moment(dateInfo.startStr).format('YYYY-MM-DD');
        const end = moment(dateInfo.endStr).subtract(1, 'days').format('YYYY-MM-DD');

        setStartDate(start);
        setEndDate(end);
    };

    const handleSelect = (selectInfo) => {
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();

        const end = moment(selectInfo.endStr).subtract(1, 'days').format('YYYY-MM-DD');
        const defaultValues = {
            start_date: selectInfo.startStr,
            end_date: end
        };

        handleOpenCreate(defaultValues);
    };

    const handleClickEvent = ({ event }) => {
        if (event?.extendedProps?.editable) {
            handleOpenEdit(event.id);
        }
    };

    const handleChangeEvent = async (changeInfo) => {
        if (changeInfo?.event?.extendedProps?.record && changeInfo?.event?.startStr && changeInfo?.event?.endStr) {
            const end = moment(changeInfo.event.endStr).subtract(1, 'days').format('YYYY-MM-DD');
            const payload = {
                id: changeInfo.event.id,
                data: {
                    ...changeInfo.event.extendedProps.record,
                    start_date: changeInfo?.event?.startStr,
                    end_date: end
                }
            };
            updateLeave('leaves', payload);
        }
    };

    return (
        <>
            <Card sx={cardSx}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek',
                    }}
                    selectable={true}
                    firstDay={1}
                    events={data?.map(convertToEvent)}
                    datesSet={handleDatesSet}
                    select={handleSelect}
                    eventClick={handleClickEvent}
                    eventChange={handleChangeEvent}
                    locale={locale === 'fr' ? frLocale : null}
                    ref={calendarRef}
                />
            </Card>

            <Dialog open={showDialogEdit} onClose={handleCloseEdit}>
                {currentId && <LeaveEdit id={currentId} actions={false} sx={formSx} redirect={false} />}
            </Dialog>

            <Dialog open={showDialogCreate} onClose={handleCloseCreate}>
                <LeaveCreate actions={false} sx={formSx} defaultValues={defaultValuesLeave} redirect={false} />
            </Dialog>
        </>
    );
};

export default LeaveCalendar;