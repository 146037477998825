import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import MonthlyList from './MonthlyList';
import MonthlyEdit from './MonthlyEdit';
import MonthlyCreate from './MonthlyCreate';
import MonthlyShow from './MonthlyShow';


const monthlies = {
	list: MonthlyList,
	edit: MonthlyEdit,
	create: MonthlyCreate,
	show: MonthlyShow,
	icon: CalendarMonthIcon
};

export default monthlies;
