import React, { useState } from 'react';
import { Link, ShowButton, EditButton, Button, useTranslate, usePermissions, useRecordContext, useLocaleState } from 'react-admin';
import { Card, CardHeader, CardContent, IconButton as MUIIconButton, Menu, MenuItem, Typography, Divider, Box, Collapse } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';

import MultilineTextField from '../common/fields/MultilineTextField';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';

const NOW = moment();

const menuItemSx = {
    padding: 0,
    '& a': {
        width: '100%',
        minHeight: '36px',
        justifyContent: 'flex-start',
        padding: '4px 10px'
    }
};

const contentCollapseSx = {
    WebkitMaskImage: 'linear-gradient(0deg, transparent 16px, white 80px)'
};

const ActionButtons = (props) => {
    const record = useRecordContext(props.record) || props?.record;
    const [anchorEl, setAnchorEl] = useState(null);
    const { permissions } = usePermissions();
    const open = Boolean(anchorEl);
    const hasPermission = permissions?.includes('is_admin') && NOW.diff(moment(record?.created_at), 'days') <= 35;

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MUIIconButton onClick={handleOpen} size="small">
                <MoreVertIcon />
            </MUIIconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem sx={menuItemSx}><ShowButton record={record} resource="monthlies" /></MenuItem>
                {hasPermission && <MenuItem sx={menuItemSx}><EditButton record={record} resource="monthlies" /></MenuItem>}
            </Menu>
        </>
    );
};

const Title = ({ record, title }) => {
    const [locale] = useLocaleState();
    const month = new Date(record.created_at).toLocaleString(locale, { month: 'long' });

    return (
        title ? title : (
            <Link to={`/monthlies/${record?.id}/show`} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '10px', textTransform: 'capitalize' }}>
                {record?.created_at ? month : ''} <DateField record={record} source="created_at" />
            </Link>
        )
    );
};

const MonthlyCard = ({ record, title, actions = <ActionButtons record={record} />, sx, collapse = false }) => {
    const [open, setOpen] = useState(!collapse || false);
    const translate = useTranslate();
    
    const handleSeeMore = () => {
        setOpen(true);
    };

    if (!record) {
        return null;
    }

    return (
        <Card sx={{ position: 'relative', height: '100%', ...sx }}>
            <CardHeader
                title={<Title record={record} title={title} />}
                action={actions}
                sx={{ '& .MuiCardHeader-action': { marginTop: 0, marginBottom: 0 } }}
            />
            <Collapse collapsedSize="450px" in={open} sx={collapse && !open ? contentCollapseSx : {}}>
                <CardContent>
                    {record?.priorities && (
                        <>
                            <Typography variant="h6">{translate('Priorities')}</Typography>
                            <RichTextField source="priorities" record={record} />
                        </>
                    )}
                    {(record?.priorities && record?.tasks) && (
                        <Divider sx={{ margin: '30px 0' }} />
                    )}

                    {record?.tasks && (
                        <>

                            <Typography variant="h6">{translate('Tasks')}</Typography>
                            <RichTextField source="tasks" record={record} />
                        </>
                    )}

                    {record?.notes && (
                        <>
                            <Divider sx={{ margin: '30px 0' }} />
                            <MultilineTextField source="notes" record={record} />
                        </>
                    )}
                </CardContent>
            </Collapse>
            {(collapse && !open) && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px 0' }}>
                    <Button variant="outlined" onClick={handleSeeMore}>{translate('See more')}</Button>
                </Box>
            )}
        </Card>
    );
};

export default MonthlyCard;