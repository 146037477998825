import React, { useState } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import { useTiptapEditor } from 'ra-input-rich-text';
import { ToggleButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import ImageExtension from '@tiptap/extension-image';


const useStyles = makeStyles({
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        marginTop: '15px'
    }
});

const ImageButton = ({ onUpload, ...props }) => {
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const translate = useTranslate();
    const editor = useTiptapEditor();
    const notify = useNotify();
    const classes = useStyles();
    const label = translate('ra.tiptap.image', { _: 'Image' });

    const handleChangeImageUrl = (e) => {
        setImageUrl(e.target.value);
    };

    const handleShowModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setImageUrl('');
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            let fr = new FileReader();

            fr.onload = function () {
                resolve(fr.result);
            }

            fr.onerror = function () {
                reject(fr);
            }

            fr.readAsDataURL(file);
        });
    };

    const handleUpload = async (file) => {
        const result = await readFileAsDataURL(file);
        return result;
    };

    const onDropImage = async (files) => {
        const [image] = files;

        try {
            setIsSubmitting(true);

            if (image) {
                if (image.size >= 5000000) {
                    throw new Error("Size of the image must be below 5MB.");
                } else {
                    let src;
                    if (onUpload) {
                        src = await onUpload(image);
                    }
                    else {
                        src = await handleUpload(image);
                    }

                    if (src) {
                        editor.chain().focus().setImage({ src }).run();
                    }
                    handleCloseModal();
                }
            }

            setIsSubmitting(false);
        } catch (error) {
            notify(translate('Image too big'), { type: 'warning' });
            setIsSubmitting(false);
        }
    };

    const handleAddImageWithUrl = () => {
        if (imageUrl) {
            editor.chain().focus().setImage({ src: imageUrl }).run();
        }
        handleCloseModal();
    };

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        accept: 'image/*',
        disabled: isSubmitting,
        onDrop: onDropImage,
    });

    return (
        <>
            <ToggleButton
                aria-label={label}
                title={label}
                {...props}
                disabled={!editor?.isEditable}
                value="image"
                onClick={handleShowModal}
            >
                <ImageIcon fontSize="inherit" />
            </ToggleButton>
            <Dialog onClose={handleCloseModal} open={open}>
                <DialogTitle>Image</DialogTitle>
                <DialogContent>
                    <Box display="flex" sx={{ paddingTop: '15px' }}>
                        <TextField label="URL" variant="outlined" type="url" size="small" value={imageUrl} onChange={handleChangeImageUrl} sx={{ width: '100%', marginRight: '15px' }} />
                        <Button variant="contained" onClick={handleAddImageWithUrl}>OK</Button>
                    </Box>
                    <div {...getRootProps({ className: classes.dropzone })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n drop an image here, or click to select image</p>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

const Image = ImageExtension.configure({
    inline: true,
    allowBase64: true
});

export { Image, ImageButton };