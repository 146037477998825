import React from 'react';
import { ImportButton } from 'react-admin-import-csv';
import moment from 'moment';

import { fetchTimeEntryTypes, fetchProjects } from '../../../services/api';

const CustomImportButton = (props) => {
    const importOptions = {
        disableUpdateMany: true,
        disableCreateMany: true,
        parseConfig: {
            delimiter: ''
        },
        transformRows: async (rows) => {
            let records = [];
            let types, projects;

            if (rows?.length > 0) {
                const responseTypes = await fetchTimeEntryTypes({ page_size: 300 });
                const responseProjects = await fetchProjects({ page_size: 300 });

                types = responseTypes?.results;
                projects = responseProjects?.results;
            }

            rows.forEach(row => {
                if (row.date && row.type && row.description && row.hours && row.project) {
                    let type, project;
                    let date = moment(row.date, 'DD/MM/YYYY');
                    if (!date.isValid()) {
                        date = moment(row.date);
                    }

                    if (row.type) {
                        type = types?.find(item => item?.name?.toLowerCase().replace(/\s/g, '') === row.type?.toLowerCase().replace(/\s/g, ''));
                    }
                    if (row.project) {
                        const rowProject = row.project?.toLowerCase()?.replace(/\s/g, '');
                        project = projects?.find(item => item?.alias?.toLowerCase().replace(/\s/g, '') === rowProject || item?.name?.toLowerCase().replace(/\s/g, '') === rowProject);
                    }

                    records.push({
                        date: date.format('YYYY-MM-DD'),
                        description: row.description,
                        project_id: project?.id ? project.id : null,
                        type_id: type?.id ? type.id : null,
                        hours: parseFloat(row.hours.replace(/,/, '.')),
                        notes: row.notes,
                        invoiced: row.invoiced ? JSON.parse(row.invoiced) : false
                    });
                }
            });
            return records;
        }
    };

    return (
        <ImportButton {...importOptions} {...props} />
    );
};

export default CustomImportButton;