import React from 'react';
import { TabbedShowLayout, Tab, TextField, BooleanField, ReferenceManyField, useRecordContext, useTranslate, usePermissions } from 'react-admin';
import MUILink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';

import Show from '../common/views/Show';
import IconCard from '../common/IconCard';
import ColorField from '../common/fields/ColorField';
import ProjectRow from '../projects/ProjectList/ProjectRow';
import AchievementsGrid from '../account/AchievementsGrid';

const ProjectArrayField = (props) => (
	<ReferenceManyField {...props} reference="projects" target="user_id">
		<ProjectRow />
	</ReferenceManyField>
);

const Link = (props) => (
	<MUILink {...props} target="_blank" rel="noreferrer noopener" />
);

const LinksGrid = () => {
	const record = useRecordContext();
	const translate = useTranslate();
	const { permissions } = usePermissions();

	return (
		<Grid container spacing={2} sx={{ margin: '10px 0' }}>
			{(record.documents_url && permissions?.includes('is_admin')) && (
				<Grid item lg={2} xl={2} sm={6} xs={12}>
					<Link href={record.documents_url}>
						<IconCard
							title={translate('Documents')}
							icon={<AddToDriveIcon />}
							variant="rounded"
							size="small"
							color={record?.color}
						/>
					</Link>
				</Grid>
			)}
		</Grid>
	);
};

const Achievements = () => {
	const record = useRecordContext();

	if (!record?.id) {
		return null;
	}

	return (
		<Box width="100%">
			<AchievementsGrid userId={record.id} />
		</Box>
	);
};

const UserShow = props => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="Summary">
				<TextField source="id" />
				<BooleanField source="active" />
				<TextField source="email" />
				<TextField source="firstname" />
				<TextField source="lastname" />
				<TextField source="trigram" />
				<ColorField source="color" />
				<BooleanField source="is_admin" label="Admin" />
				<BooleanField source="is_staff" label="Staff" />
				<LinksGrid label={false} />
			</Tab>
			<Tab label="Projects">
				<ProjectArrayField label={false} />
			</Tab>
			<Tab label="Achievements">
				<Achievements label={false} />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default UserShow;