import React from 'react';
import PropTypes from 'prop-types';
import { ChipField as RAChipField } from 'react-admin';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
	small: {
		height: '20px',
	}
});

const ChipField = ({ record, source, color = 'default', size = 'medium', ...props }) => {
	const classes = useStyles();

	return (
		record && (record[source] !== undefined && record[source] !== null) ? 
			<RAChipField className={size === 'small' ? classes.small : ''} {...{ record, source, size, color, ...props }} /> 
		: null
	);
};

ChipField.propTypes = {
	source: PropTypes.string.isRequired,
	size: PropTypes.oneOf(["small", "medium"]),
	color: PropTypes.string
};

export default ChipField;