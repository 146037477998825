import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardActions, Button, OutlinedInput, FormControl, InputLabel, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Login from './Login';
import { resetPassword } from '../../services/api';


const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	},
	form: {
		padding: '0 8px 8px 8px'
	}
}));

const ResetPasswordPage = () => {
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const classes = useStyles();
	const { token } = useParams();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password) {
			setLoading(true);
			const formData = new FormData();
			formData.append('token', token);
			formData.append('password', password);
			const response = await resetPassword(formData);
			setLoading(false);
			if (response.success) {
				navigate('/');
			}
			else {
				setError(response.message);
			}
		}
	};

	return (
		<Login>
			<form onSubmit={handleSubmit}>
				<div className={classes.form}>
					<FormControl fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<OutlinedInput
							id="password"
							name="password"
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							inputProps={{ minLength: 6 }}
							error={!!error}
							label="Password"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{error && <FormHelperText id="password" error>{error}</FormHelperText>}
					</FormControl>
				</div>
				<CardActions>
					<Button
						variant="contained"
						type="submit"
						color="primary"
						disabled={loading}
						fullWidth
					>
						{loading && (
							<CircularProgress
								className={classes.icon}
								size={18}
								thickness={2}
							/>
						)}
						Reset password
					</Button>
				</CardActions>
			</form>
		</Login>
	);
};

export default ResetPasswordPage;
