import React, { useState } from 'react';
import { Link, ShowButton, EditButton, Button, useTranslate, usePermissions, useGetIdentity, useRecordContext } from 'react-admin';
import { Card, CardHeader, CardContent, IconButton as MUIIconButton, Menu, MenuItem, Box, Typography, Divider, Collapse } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import moment from 'moment';

import MultilineTextField from '../common/fields/MultilineTextField';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';
import WorkloadField from './fields/WorkloadField';

const NOW = moment();

const menuItemSx = {
    padding: 0,
    '& a': {
        width: '100%',
        minHeight: '36px',
        justifyContent: 'flex-start',
        padding: '4px 10px'
    }
};

const contentCollapseSx = {
    WebkitMaskImage: 'linear-gradient(0deg, transparent 16px, white 80px)'
};

const getWorkloadBoxSx = ({ workload }) => {
    let sx = { width: '6px', height: '100%', position: 'absolute' };
    switch (workload) {
        case 'low':
        case 'normal':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? green[700] : green[200] };
            break;
        case 'high':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'available':
        case 'pending':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? blue[700] : blue[100] };
            break;
        case 'overloaded':
        case 'blocked':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[700] : grey[200] };
            break;
    }

    return sx;
};

const ActionButtons = (props) => {
    const record = useRecordContext(props.record) || props?.record;
    const [anchorEl, setAnchorEl] = useState(null);
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const open = Boolean(anchorEl);
    const hasPermission = permissions?.includes('is_admin') || (NOW.diff(moment(record?.created_at), 'days') <= 14 && record?.user_id === account?.id);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MUIIconButton onClick={handleOpen} size="small">
                <MoreVertIcon />
            </MUIIconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem sx={menuItemSx}><ShowButton record={record} resource="weeklies" /></MenuItem>
                {hasPermission && <MenuItem sx={menuItemSx}><EditButton record={record} resource="weeklies" /></MenuItem>}
            </Menu>
        </>
    );
};

const Title = ({ record, title }) => (
    title ? title : (
        <Link to={`/weeklies/${record?.id}/show`} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '10px' }}>
            {record?.user?.fullname} <DateField record={record} source="created_at" /> <WorkloadField record={record} />
        </Link>
    )
);

const WeeklyCard = ({ record, title, actions = <ActionButtons record={record} />, showWorkloadColor = true, sx, collapse = false }) => {
    const [open, setOpen] = useState(!collapse || false);
    const translate = useTranslate();

    const handleSeeMore = () => {
        setOpen(true);
    };

    if (!record) {
        return null;
    }

    return (
        <Card sx={{ position: 'relative', height: '100%', ...sx }}>
            {showWorkloadColor && <Box sx={getWorkloadBoxSx(record)} />}
            <CardHeader
                title={<Title record={record} title={title} />}
                action={actions}
                sx={{ '& .MuiCardHeader-action': { marginTop: 0, marginBottom: 0 } }}
            />
            <Collapse collapsedSize="450px" in={open} sx={collapse && !open ? contentCollapseSx : {}}>
                <CardContent>
                    {record?.priorities && (
                        <>
                            <Typography variant="h6">{translate('Priorities')}</Typography>
                            <RichTextField source="priorities" record={record} />
                        </>
                    )}
                    {(record?.priorities && record?.tasks) && (
                        <Divider sx={{ margin: '30px 0' }} />
                    )}

                    {record?.tasks && (
                        <>

                            <Typography variant="h6">{translate('Tasks')}</Typography>
                            <RichTextField source="tasks" record={record} />
                        </>
                    )}

                    {record?.notes && (
                        <>
                            <Divider sx={{ margin: '30px 0' }} />
                            <MultilineTextField source="notes" record={record} />
                        </>
                    )}
                </CardContent>
            </Collapse>
            {(collapse && !open) && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px 0' }}>
                    <Button variant="outlined" onClick={handleSeeMore}>{translate('See more')}</Button>
                </Box>
            )}
        </Card>
    );
};

export default WeeklyCard;