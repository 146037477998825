import React from 'react';
import { List, TopToolbar, ReferenceInput, AutocompleteInput, SearchInput, SortButton, useStore, useTranslate, useGetIdentity } from 'react-admin';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Chip } from '@mui/material';

import { DefaultListActions } from '../../common/views/List';
import WeeklyRow from './WeeklyRow';
import WeeklyGrid from './WeeklyGrid';

const buttonGroupSx = { marginBottom: '-2px' };
const inactiveButtonSx = { color: (theme) => theme.palette.action.disabled };

const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const ViewButton = ({ viewGrid, setViewGrid }) => {
    const handleClickList = () => {
        setViewGrid(false);
    };

    const handleClickGrid = () => {
        setViewGrid(true);
    };

    return (
        <ButtonGroup size="small" variant="text" sx={buttonGroupSx}>
            <Button onClick={handleClickGrid} sx={viewGrid ? {} : inactiveButtonSx}><ViewModuleIcon /></Button>
            <Button onClick={handleClickList} sx={viewGrid ? inactiveButtonSx : {}}><ViewHeadlineIcon /></Button>
        </ButtonGroup>
    );
};

const WeeklyListActions = (props) => (
    <TopToolbar>
        <DefaultListActions>
            {props.viewGrid && <SortButton fields={['workload', 'user_id']} />}
            <ViewButton {...props} />
        </DefaultListActions>
    </TopToolbar>
);

const QuickFilter = ({ label }) => {
    const translate = useTranslate();

    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const getWeeklyFilters = (account) => (
    [
        <SearchInput source="q" alwaysOn />,
        <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={userOptionText} size="small" />
        </ReferenceInput>,
        <QuickFilter source="user_id" label="My weeklies only" defaultValue={account?.id} />
    ]
);

const WeeklyList = props => {
    const [viewGrid, setViewGrid] = useStore('weeklies.list.viewGrid', true);
    const { data: account } = useGetIdentity();

    let customProps = {};
    if (viewGrid) {
        customProps = { component: 'div' };
    }

    return (
        <List
            perPage={25}
            actions={<WeeklyListActions viewGrid={viewGrid} setViewGrid={setViewGrid} />}
            filters={getWeeklyFilters(account)}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}
            {...customProps}
        >
            {viewGrid ? (
                <WeeklyGrid />
            ) : (
                <WeeklyRow />
            )}
        </List>
    );
};

export default WeeklyList;