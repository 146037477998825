import React, { useState, useEffect } from 'react';
import { Title, Link, CreateButton, useTranslate, usePermissions, useGetList, useGetIdentity } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import moment from 'moment';

import IconCard from '../common/IconCard';
import HumanDateField from '../common/fields/HumanDateField';
import WeeklyCard from '../weeklies/WeeklyCard';
import MonthlyCard from '../monthlies/MonthlyCard';
import PendingLeaves from './PendingLeaves';
import NextLeaves from './NextLeaves';
import MyProjectsCard from './MyProjectsCard';
import MyLastIssuesCard from './MyLastIssuesCard';
import TimeEntryBarChartCard from './TimeEntryBarChartCard';

import customers from '../customers';
import projects from '../projects';

import { fetchDashboardStats } from '../../services/api';

const NOW = moment();
const NOW_DATE = NOW.format('YYYY-MM-DD');

const Dashboard = () => {
    const [stats, setStats] = useState();
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const { data: lastTimeEntries } = useGetList(
        'time_entries',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'date', order: 'DESC' },
            filter: { user_id: account?.id }
        }
    );
    const { data: lastWeeklies } = useGetList(
        'weeklies',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'created_at', order: 'DESC' },
            filter: { user_id: account?.id }
        }
    );
    const { data: lastMonthlies } = useGetList(
        'monthlies',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'created_at', order: 'DESC' }
        }
    );

    let warningTimesheet = false;
    if (lastTimeEntries?.length > 0) {
        const lastEntryDate = lastTimeEntries[0].date;
        warningTimesheet = NOW.diff(moment(lastEntryDate), 'days') >= 7;
    }
    let warningWeekly = false;
    if (lastWeeklies?.length > 0) {
        const lastWeeklyDate = lastWeeklies[0].created_at;
        warningWeekly = NOW.diff(moment(lastWeeklyDate), 'days') >= 7;
    }
    let warningMonthly = false;
    if (lastMonthlies?.length > 0) {
        const lastMonthlyDate = lastMonthlies[0].created_at;
        warningMonthly = NOW.diff(moment(lastMonthlyDate), 'days') >= 30;
    }

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Box sx={{ marginBottom: '30px' }}>
            <Title title="Kernel" />
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                {permissions?.includes('is_admin') && (
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/customers">
                            <IconCard
                                title={translate('resources.customers.name', { smart_count: 2, _: "Customers" })}
                                value={stats?.customers}
                                icon={<customers.icon />}
                            />
                        </Link>
                    </Grid>
                )}
                {permissions?.includes('is_admin') ? (
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/projects">
                            <IconCard
                                title={translate('resources.projects.name', { smart_count: 2, _: "Projects" })}
                                value={stats?.projects}
                                icon={<projects.icon />}
                            />
                        </Link>
                    </Grid>
                ) : (
                    <MyProjectsCard />
                )}
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/leaves?filter={"user_id":${account?.id}}`}>
                        <IconCard title={translate('Total remaining leave')} value={`${account?.remaining_leaves || 0}/26`} icon={<EventAvailableIcon />} />
                    </Link>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                {permissions?.includes('is_admin') && (
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <IconCard
                            title={translate('Pending leaves')}
                            value={stats?.leaves?.pending}
                            icon={<EditCalendarIcon />}
                            warning={stats?.leaves?.pending > 0}
                            content={<PendingLeaves />}
                            sx={{ height: '100%' }}
                            linkTo='/leaves?filter={"status": "pending"}'
                        />
                    </Grid>
                )}
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard
                        title={translate('Next leaves')}
                        icon={<CalendarMonthIcon />}
                        content={<NextLeaves />}
                        sx={{ height: '100%' }}
                        linkTo={`/leaves?filter={"status":"accepted","start_date":"${NOW_DATE}"}&sort=start_date&order=ASC`}
                    />
                </Grid>
                {(warningTimesheet && lastTimeEntries?.length > 0) ? (
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to='/time_entries/create'>
                            <IconCard
                                title={translate('Last time entry')}
                                value={new Date(lastTimeEntries[0].date).toLocaleDateString('fr-FR')}
                                icon={<AccessTimeIcon />}
                                warning
                            />
                        </Link>
                    </Grid>
                ) : (
                    account?.id && (
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <TimeEntryBarChartCard />
                        </Grid>
                    )
                )}
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <MyLastIssuesCard />
                </Grid>
            </Grid>

            {(lastWeeklies?.length > 0 && lastMonthlies?.length > 0) && (
                <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                    {lastMonthlies?.length > 0 && (
                        <Grid item lg={6} xl={6} sm={6} xs={12}>
                            <MonthlyCard
                                record={lastMonthlies[0]}
                                title={
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Link to={`/monthlies/${lastMonthlies[0].id}/show`} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '12px' }}>
                                            {translate('Last monthly')} <HumanDateField source="created_at" record={lastMonthlies[0]} />
                                        </Link>
                                        {(warningMonthly && permissions?.includes('is_admin')) && (
                                            <Box sx={{ margin: '0 10px' }}>
                                                <CreateButton resource="monthlies" variant="outlined" color="warning" />
                                            </Box>
                                        )}
                                    </Box>
                                }
                                sx={{ height: 'auto' }}
                                collapse
                            />
                        </Grid>
                    )}
                    {lastWeeklies?.length > 0 && (
                        <Grid item lg={6} xl={6} sm={6} xs={12}>
                            <WeeklyCard
                                record={lastWeeklies[0]}
                                title={
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Link to={`/weeklies/${lastWeeklies[0].id}/show`} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '12px' }}>
                                            {translate('Last weekly')} <HumanDateField source="created_at" record={lastWeeklies[0]} />
                                        </Link>
                                        {warningWeekly && (
                                            <Box sx={{ margin: '0 10px' }}>
                                                <CreateButton resource="weeklies" variant="outlined" color="warning" />
                                            </Box>
                                        )}
                                    </Box>
                                }
                                sx={{ height: 'auto' }}
                                collapse
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Box>
    );
};

export default Dashboard;