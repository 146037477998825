import React, { useEffect, useState } from 'react';
import { Title, TextInput, PasswordInput, Toolbar, SaveButton, useNotify, useTranslate, required, minLength, useAuthState, useAuthenticated, useGetIdentity } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SimpleForm from '../common/forms/SimpleForm';
import ColorInput from '../common/inputs/ColorInput';
import AchievementsGrid from './AchievementsGrid';

import { getAccount, updateAccount } from '../../services/api';
import { changeTheme } from '../../actions/theme';


const inputSx = { minWidth: '250px' };

const AccountToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
);

const TabPanel = ({ children, value, index, ...props }) => (
    <Box role="tabpanel" hidden={value !== index} {...props}>
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </Box>
);

const Account = () => {
    const [account, setAccount] = useState({});
    const [tab, setTab] = useState(0);
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();
    const notify = useNotify();
    const translate = useTranslate();
    const { isLoading, authenticated } = useAuthState();
    const { refetch } = useGetIdentity();
    useAuthenticated();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAccount();
                if (response?.id) {
                    setAccount({
                        id: response.id,
                        firstname: response.firstname,
                        lastname: response.lastname,
                        color: response.color
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (!isLoading && authenticated) {
            fetchData();
        }
    }, [isLoading, authenticated]);

    const handleChangeTheme = () => {
        dispatch(changeTheme(theme === 'light' ? 'dark' : 'light'));
    };

    const handleSubmit = async (data) => {
        const response = await updateAccount(JSON.stringify(data));
        if (response.errors) {
            let message = '';
            for (const error in response.errors) {
                message += `${error}: ${response.errors[error]}\n`;
            }
            notify(message, "warning");
        }
        else {
            notify("Account updated");
            refetch();
        }
    };

    const handleChangeTab = (e, value) => {
        setTab(value);
    };

    return (
        <Box my={3}>
            <Title title="Account" />
            <Card>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleChangeTab}>
                        <Tab label={translate('Account')} />
                        <Tab label={translate('Achievements')} />
                    </Tabs>
                </Box>
                <TabPanel value={tab} index={0}>
                    <SimpleForm record={account} onSubmit={handleSubmit} resource="account" toolbar={<AccountToolbar />}>
                        <TextInput source="firstname" validate={required()} sx={inputSx} />
                        <TextInput source="lastname" validate={required()} sx={inputSx} />
                        <Box sx={{ ...inputSx, marginBottom: '20px' }}>
                            <ColorInput sx={inputSx} />
                        </Box>
                        <PasswordInput source="password" validate={minLength(8)} />
                        <>
                            <FormControl>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={<Switch color="primary" onChange={handleChangeTheme} checked={theme === 'dark'} />}
                                        label="Dark theme"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                        </>
                    </SimpleForm>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <AchievementsGrid userId={account?.id} />
                </TabPanel>
            </Card>
        </Box>
    );
};

export default Account;