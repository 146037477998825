import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import { useTranslate } from 'react-admin';

import { search } from '../../../../services/api';

import 'semantic-ui-css/components/search.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/input.css';


const styles = {
	flex: 1,
	marginLeft: '25px'
};

const SearchBar = ({ staticContext, ...props }) => {
	const [value, setValue] = useState('');
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const translate = useTranslate();

	const handleResultSelect = (e, { result }) => {
		navigate(result.url);
		setValue('');
		setResults([]);
	};

	const handleSearchChange = async (e, { value }) => {
		setIsLoading(true);
		setValue(value);
		setResults([]);
	};

	useEffect(() => {
		const timerId = setTimeout(async () => {
			if (value.length >= 3) {
				const data = await search(value);
				let cleanData = {};
				for (const [key, value] of Object.entries(data)) {
					if (value?.results?.length > 0) {
						cleanData = { ...cleanData, [key]: value };
					}
				}
				setIsLoading(false);
				setResults(cleanData);
			}
			else if (value.length === 0) {
				setIsLoading(false);
				setResults([]);
			}
		}, 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [value]);

	return (
		<Search
			category
			minCharacters={2}
			loading={isLoading}
			onResultSelect={handleResultSelect}
			onSearchChange={handleSearchChange}
			results={results}
			value={value}
			placeholder={translate("Search") + "..."}
			style={styles}
			size="small"
			showNoResults={false}
			{...props}
		/>
	);
};

export default SearchBar;
