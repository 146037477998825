import React from 'react';
import { TextInput } from 'react-admin';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';

const TimeEntryTypeCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" multiline fullWidth rows="4" />
        </SimpleForm>
    </Create>
);

export default TimeEntryTypeCreate;
