import React from 'react';
import { Login } from 'react-admin';

const sx = {
	backgroundImage: 'radial-gradient(circle at 50% 14em, #464646 0%, #262626 60%, #121212 100%)',
	'& .MuiCard-root': {
		paddingBottom: (theme) => theme.spacing(2)
	}
};

const CustomLogin = (props) => (
	<Login sx={sx} {...props} />
);

export default CustomLogin;
