import React from 'react';
import { SelectInput } from 'react-admin';

const choices = [
    { id: 'available', name: 'Available' },
    { id: 'pending', name: 'Pending' },
    { id: 'low', name: 'Low' },
    { id: 'normal', name: 'Normal' },
    { id: 'high', name: 'High' },
    { id: 'overloaded', name: 'Overloaded' },
    { id: 'blocked', name: 'Blocked' }
];

const WorkloadInput = ({ source = 'workload', ...props }) => (
    <SelectInput source="workload" choices={choices} {...props} />
);

export default WorkloadInput;
