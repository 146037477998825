import React from 'react';
import { LoginForm, Link } from 'react-admin';
import { makeStyles } from '@mui/styles';

import Login from './Login';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'block',
		textAlign: 'center',
		textDecoration: 'underline',
		margin: theme.spacing(1)
	},
	form: {
		'& .ra-input': {
			width: '100%'
		}
	}
}));

const LoginPage = () => {
	const classes = useStyles();

	return (
		<Login>
			<LoginForm className={classes.form} />
			<Link to="/forgot-password" className={classes.link}>Forgot password?</Link>
		</Login>
	);
};

export default LoginPage;
