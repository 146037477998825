import React from 'react';
import YoutubeExtension from '@tiptap/extension-youtube';
import { useTiptapEditor } from 'ra-input-rich-text';
import { ToggleButton } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';


const VideoButton = (props) => {
    const editor = useTiptapEditor();

    const addYoutubeVideo = () => {
        const url = prompt('Enter YouTube or Vimeo URL');
        if (!url) {
            return;
        }

        if (url.match(/vimeo/)) {
            if (url.includes('/player.vimeo.com')) {
                editor.chain().focus().insertContent(`<iframe width="640" height="480" src="${url}" class="video" />`).run();
            }
            else {
                const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
                const matches = url.match(vimeoRegex);
                const vimeoUrl = `https://player.vimeo.com/video/${matches[1]}`
                editor.chain().focus().insertContent(`<iframe width="640" height="480" src="${vimeoUrl}" class="video" />`).run();
            }
        }
        else {
            editor.commands.setYoutubeVideo({
                src: url
            });
        }
    };

    return (
        <ToggleButton
            aria-label="Video"
            title="Video"
            {...props}
            disabled={!editor?.isEditable}
            value="youtube"
            onClick={addYoutubeVideo}
        >
            <YouTubeIcon fontSize="inherit" />
        </ToggleButton>
    );
};

const Youtube = YoutubeExtension.configure({
    addPasteHandler: false,
    HTMLAttributes: {
        class: 'video'
    }
});

export { VideoButton, Youtube };