import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	icon: {
		fontSize: '20px',
		paddingRight: '5px'
	}
});

const IconButton = ({ size = 'small', color = 'primary', icon, children, ...props }) => {
	const classes = useStyles();
	const customIcon = React.cloneElement(icon, { className: classes.icon });

	return (
		<Button size={size} color={color} {...props} sx={size === 'small' ? { lineHeight: 1.5 } : {}}>
			{customIcon}
			{children}
		</Button>
	);
};

IconButton.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
	icon: PropTypes.element.isRequired
};

export default IconButton;
