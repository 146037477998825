import DateRangeIcon from '@mui/icons-material/DateRange';

import WeeklyList from './WeeklyList';
import WeeklyEdit from './WeeklyEdit';
import WeeklyCreate from './WeeklyCreate';
import WeeklyShow from './WeeklyShow';


const weeklies = {
	list: WeeklyList,
	edit: WeeklyEdit,
	create: WeeklyCreate,
	show: WeeklyShow,
	icon: DateRangeIcon
};

export default weeklies;
