import React from 'react';
import { RecordContextProvider, useListContext } from 'react-admin';
import Grid from '@mui/material/Grid';

import MonthlyCard from '../MonthlyCard';

const MonthlyGrid = () => {
    const { data } = useListContext();

    return (
        <Grid container spacing={2} sx={{ mt: 0 }}>
            {data?.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Grid key={record.id} item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MonthlyCard record={record} key={`monthly_${record?.id}`} />
                    </Grid>
                </RecordContextProvider>
            ))}
        </Grid>
    );
};

export default MonthlyGrid;