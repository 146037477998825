import React from 'react';
import { List, SearchInput } from 'react-admin';

import TimeEntryTypeRow from './TimeEntryTypeRow'

const timeEntryTypeFilters = [
    <SearchInput source="q" alwaysOn />
];

const TimeEntryTypeList = props => (
    <List filters={timeEntryTypeFilters} {...props}>
        <TimeEntryTypeRow />
    </List>
);

export default TimeEntryTypeList;