import React from 'react';
import { useRecordContext, useTranslate, useLocaleState } from 'react-admin';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import 'moment/locale/fr';

const HumanDateField = ({ className, source, basePath, ...props }) => {
    const record = useRecordContext() || props.record;
    const translate = useTranslate();
    const [locale] = useLocaleState();
    const momentValue = get(record, source) ? moment(get(record, source)) : null;
    const now = moment.now();
    const value = momentValue?.isValid() ? moment.duration(momentValue - now).locale(locale).humanize(true) : translate('Never');

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...props}
        >
            {value && typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

export default HumanDateField;