import React, { useState, useEffect } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { fetchTimeEntriesStats } from '../../../services/api';
import { stringToColor } from '../../../services/utils';

const tooltipSx = {
    backgroundColor: theme => theme.palette.background.paper,
    border: '1px solid rgb(204, 204, 204)',
    padding: '0 5px'
};

const MAX_HOURS_DAY = 8;

const CustomToolTip = ({ active, payload, label, freq }) => {
    if (!active || !payload) {
        return null;
    }

    let title = new Date(label).toLocaleString('default', { day: 'numeric', month: 'numeric', year: 'numeric' });
    if (freq === 'yearly') {
        title = new Date(label).getFullYear();
    }
    if (freq === 'monthly') {
        title = new Date(label).toLocaleDateString('default', { month: 'numeric', year: 'numeric' });
    }

    return (
        <Box sx={tooltipSx}>
            <p><strong>{title}</strong></p>
            {payload.map((item, i) => (
                <p key={i}>{item.name}: {item.value}h</p>
            ))}
        </Box>
    );
};

const TimeEntryBarChart = ({ showLegend = true, showTotal = true, projectId, customerId, typeId, userId, startDate, endDate, freq, groupBy = 'date', disableFetch = false, referenceLineLabel, ...props }) => {
    const [data, setData] = useState(props.data);
    const [total, setTotal] = useState(props.total);
    const translate = useTranslate();
    const { total: numberUsers } = useGetList('users', { filter: { 'is_staff': true } });
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchTimeEntriesStats({
                project_id: projectId,
                customer_id: customerId,
                type_id: typeId,
                user_id: userId,
                start_date: startDate,
                end_date: endDate,
                freq,
                group_by: groupBy
            });
            if (response?.data) {
                setData(response.data);
                setTotal(response.total);

                if (props.setData) {
                    props.setData(response.data);
                }
                if (props.setTotal) {
                    props.setTotal(response.total);
                }
            }
        };

        if (!disableFetch) {
            fetchData();
        }
    }, [projectId, customerId, typeId, userId, startDate, endDate, freq, groupBy, disableFetch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setData(props.data);
        setTotal(props.total);
    }, [props.data, props.total]);

    const formatDate = (datetime) => {
        const date = new Date(datetime);

        if (freq === 'yearly') {
            return date.getFullYear();
        }
        if (freq === 'monthly') {
            return date.toLocaleString('default', { month: 'short' });
        }
        return date.toLocaleString('default', { day: 'numeric', month: 'numeric', year: 'numeric' });
    };

    if (!data) {
        return null;
    }

    const cursorStyle = { fill: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default };
    let teamCapacity = numberUsers * MAX_HOURS_DAY;
    if (userId) {
        teamCapacity = 8;
    }

    if (groupBy && groupBy !== 'date') {
        let stacks = new Map();
        const groupData = data.reduce((acc, item) => {
            let existing = acc.find(r => r.date === item.date);
            if (!existing) {
                existing = { date: item.date };
                acc.push(existing);
            }
            existing[item.name] = item.value;
            if (item.name) {
                stacks.set(item.name, { name: item.name, color: item.color });
            }
            return acc;
        }, []);
        stacks = [...stacks.values()];

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={groupData} margin={props?.margin ? props.margin : { top: 20, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="date" tickFormatter={formatDate} />
                    <YAxis />
                    <Tooltip cursor={cursorStyle} content={<CustomToolTip freq={freq} />} />
                    {showLegend && <Legend />}
                    {freq === 'daily' && (
                        <ReferenceLine y={teamCapacity} label={referenceLineLabel ? referenceLineLabel : translate('Capacity')} stroke="#CCCCCC" strokeDasharray="6 6" ifOverflow="extendDomain" />
                    )}
                    {stacks.map((stack) => {
                        if (stack.name) {
                            return (
                                <Bar name={stack.name} dataKey={stack.name} fill={stack?.color || stringToColor(stack.name)} stackId="stack" key={`stack_${stack.name}`} />
                            );
                        }
                        return null;
                    })}
                </BarChart>
                {(showTotal && total) ? <Typography>{translate('Total')}: {total}h</Typography> : null}
            </ResponsiveContainer>
        );
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <XAxis dataKey="date" tickFormatter={formatDate} />
                <YAxis />
                <Tooltip cursor={cursorStyle} content={<CustomToolTip freq={freq} />} />
                {showLegend && <Legend />}
                {freq === 'daily' && (
                    <ReferenceLine y={teamCapacity} label={translate('Capacity')} stroke="#CCCCCC" strokeDasharray="6 6" ifOverflow="extendDomain" />
                )}
                <Bar name={translate('Hours')} dataKey="value" fill="#8884d8" />
            </BarChart>
            {(showTotal && total) ? <Typography>{translate('Total')}: {total}h</Typography> : null}
        </ResponsiveContainer>
    );
};

export default TimeEntryBarChart;