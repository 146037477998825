import React from 'react';
import { Datagrid, TextField, ReferenceField, BulkDeleteButton, useRecordContext, useGetIdentity, usePermissions } from 'react-admin';
import moment from 'moment';

import DateField from '../../common/fields/DateField';
import RecordSplitButton from '../../common/buttons/RecordSplitButton';
import StatusField from '../fields/StatusField';
import TypeField from '../fields/TypeField';
import DurationField from '../fields/DurationField';

import BulkAcceptButton from '../buttons/BulkAcceptButton';
import BulkRefuseButton from '../buttons/BulkRefuseButton';

const SplitButton = (props) => {
    const record = useRecordContext();
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const hasEdit = permissions?.includes('is_admin') ||
        (record?.status === 'pending' && record?.user_id === account?.id) ||
        (moment(record?.end_date).isAfter(moment()) && record?.user_id === account?.id);
    const hasDelete = permissions?.includes('is_admin') ||
        (record?.status === 'pending' && record?.user_id === account?.id) ||
        (moment(record?.start_date).isAfter(moment()) && record?.user_id === account?.id);

    return (
        <RecordSplitButton
            {...props}
            hasEdit={hasEdit}
            hasDelete={hasDelete}
        />
    );
};

const LeaveBulkActionsButtons = () => (
    <>
        <BulkAcceptButton />
        <BulkRefuseButton />
        <BulkDeleteButton />
    </>
);

const LeaveRow = ({ children, ...props }) => (
    <Datagrid bulkActionButtons={<LeaveBulkActionsButtons />} {...props}>
        <TextField source="id" />
        <ReferenceField label="User" source="user_id" reference="users" allowEmpty link="show">
            <TextField source="fullname" />
        </ReferenceField>
        <TypeField source="type" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DurationField />
        <StatusField />
        <DateField source="updated_at" showTime />
        {children}
        <SplitButton />
    </Datagrid>
);

export default LeaveRow;